import React from 'react';
import type { FontFile } from '../types/font';
import { StepHeading } from './common/StepHeading';
import { GlyphPreview } from './preview/GlyphPreview';
import { UploadArea } from './upload/UploadArea';
import { useStepProgress } from '../hooks/useStepProgress';

interface Props {
  files: FontFile[];
  selectedFontId?: string | null;
  onFilesAdded: (files: File[]) => void;
  onFileRemove: (id: string) => void;
  onFontSelect?: (id: string) => void;
}

export function FileUpload({ 
  files, 
  selectedFontId,
  onFilesAdded, 
  onFileRemove,
  onFontSelect,
}: Props) {
  const { completeStep } = useStepProgress();

  React.useEffect(() => {
    if (files.length > 0) {
      if (onFontSelect) {
        onFontSelect(files[files.length - 1].id);
      }
      completeStep(1);
    }
  }, [files.length, onFontSelect, completeStep]);

  const selectedFont = files.find(f => f.id === selectedFontId) || files[files.length - 1];
  const title = files.length === 0 ? 'No file selected' : '';

  return (
    <div className="bg-white dark:bg-dark-surface rounded-xl border border-gray-200 dark:border-dark-border">
      <div className="p-4 bg-gray-50 dark:bg-gray-800/50 border-b border-gray-200 dark:border-dark-border rounded-t-xl">
        <StepHeading step={1} title={title} />
      </div>
      
      <div className="p-4">
        <div className={`grid ${files.length > 0 ? 'grid-cols-1 xs:grid-cols-2' : 'grid-cols-1'} gap-3 xs:gap-6`}>
          <div className="h-[600px]">
            <UploadArea
              files={files}
              selectedFontId={selectedFontId}
              onFilesAdded={onFilesAdded}
              onFileRemove={onFileRemove}
              onFontSelect={onFontSelect}
            />
          </div>

          {files.length > 0 && (
            <div className="h-[600px]">
              <GlyphPreview 
                font={selectedFont}
                fontLoaded={true}
                onFontSelect={onFontSelect}
                fonts={files}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}