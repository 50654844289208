import { useCallback } from 'react';
import { useStepProgressStore } from '../store/stepProgressStore';

export function useStepProgress() {
  const { 
    currentStep, 
    completedSteps, 
    setCurrentStep, 
    completeStep,
    isStepCompleted,
    canAdvance
  } = useStepProgressStore();

  const handleStepClick = useCallback((step: number) => {
    if (canAdvance(step)) {
      setCurrentStep(step);
      
      // Find the target element
      const element = document.querySelector(`[data-step="${step}"]`);
      if (element) {
        // Get header height for offset
        const header = document.querySelector('header');
        const headerHeight = header?.getBoundingClientRect().height || 0;
        const padding = 24; // Additional padding
        
        // Calculate scroll position with improved accuracy
        const elementRect = element.getBoundingClientRect();
        const absoluteElementTop = window.pageYOffset + elementRect.top;
        const scrollPosition = absoluteElementTop - headerHeight - padding;

        // Perform smooth scroll
        window.scrollTo({
          top: scrollPosition,
          behavior: 'smooth'
        });

        // Ensure element is focused for accessibility
        element.setAttribute('tabindex', '-1');
        element.focus({ preventScroll: true });
      }
    }
  }, [canAdvance, setCurrentStep]);

  return {
    currentStep,
    completedSteps,
    completeStep,
    isStepCompleted,
    canAdvance,
    handleStepClick
  };
}