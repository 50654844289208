import React from 'react';
import { Settings2, Type, Zap, Monitor, ArrowUpDown, Info, Rocket, Gauge, Sparkles, Cpu } from 'lucide-react';
import { BaseModal } from './BaseModal';
import { ModalActions } from './components/ModalActions';
import { useAdvancedSettings } from '../../hooks/useAdvancedSettings';
import type { FontFile } from '../../types/font';
import { Tooltip } from '../common/Tooltip';
import { OpenTypeFeatures } from '../advanced/features/OpenTypeFeatures';
import { RenderingSection } from '../advanced/sections/RenderingSection';
import { VerticalMetricsSection } from '../advanced/sections/VerticalMetricsSection';
import { OptimizationContent } from './content/OptimizationContent';
import { useSettingsStore } from '../../store/settingsStore';

const TABS = [
  { 
    id: 'optimization', 
    icon: Zap,
    name: 'Optimization', 
    description: 'File size and compression settings',
    helpText: 'Reduce file size and improve loading performance by configuring compression levels and optimization settings. Balance between file size and quality to achieve optimal results for your use case.'
  },
  { 
    id: 'features', 
    icon: Settings2,
    name: 'OpenType Features', 
    description: 'Configure font features and ligatures',
    helpText: 'OpenType features allow you to control advanced typographic features in your font. Enable or disable specific features to customize how your font renders text. You can keep all features, make a custom selection, or provide a comma-separated list.'
  },
  { 
    id: 'metrics', 
    icon: ArrowUpDown,
    name: 'Metrics', 
    description: 'X-height and vertical metrics',
    helpText: 'Vertical metrics control the spacing and alignment of your font. Adjust these settings to ensure consistent rendering across different browsers and operating systems. You can customize ascent, descent, and line gap values.'
  },
  { 
    id: 'rendering', 
    icon: Monitor,
    name: 'Rendering', 
    description: 'Font display and rendering options',
    helpText: 'Fine-tune how your font appears on screen with settings for hinting, kerning, and other rendering optimizations. These options affect the visual quality and performance of your font across different devices.'
  }
] as const;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  font?: FontFile;
}

export function AdvancedSettingsModal({ isOpen, onClose, font }: Props) {
  const [activeTab, setActiveTab] = React.useState('optimization');
  const [showInfo, setShowInfo] = React.useState(true);
  const { rememberSettings, setRememberSettings } = useSettingsStore();
  const {
    settings,
    isDirty,
    handleSettingsChange,
    handleApply,
    handleCancel
  } = useAdvancedSettings(font);

  const handleClose = () => {
    if (isDirty) {
      handleCancel();
    }
    onClose();
  };

  const handleSave = () => {
    handleApply();
    onClose();
  };

  const renderContent = () => {
    if (!font || !settings) return null;

    const activeTabData = TABS.find(tab => tab.id === activeTab);

    return (
      <div className="space-y-6">
        {showInfo && activeTabData && (
          <div className="p-4 bg-gray-50 dark:bg-gray-800/50 rounded-lg border border-gray-200 dark:border-dark-border">
            <h4 className="text-sm font-medium text-gray-900 dark:text-gray-100 mb-2">
              About {activeTabData.name}
            </h4>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              {activeTabData.helpText}
            </p>
          </div>
        )}

        {(() => {
          switch (activeTab) {
            case 'optimization':
              return (
                <OptimizationContent
                  font={font}
                  settings={settings}
                  onSettingsChange={handleSettingsChange}
                />
              );
            case 'features':
              return (
                <OpenTypeFeatures
                  settings={settings}
                  font={font}
                  onSettingsChange={handleSettingsChange}
                />
              );
            case 'metrics':
              return (
                <VerticalMetricsSection
                  value={settings.metrics}
                  onChange={metrics => handleSettingsChange({ metrics })}
                />
              );
            case 'rendering':
              return (
                <RenderingSection
                  value={settings.rendering}
                  onChange={rendering => handleSettingsChange({ rendering })}
                />
              );
            default:
              return null;
          }
        })()}
      </div>
    );
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={handleClose}
      title="Advanced Settings"
      subtitle={font?.name}
      headerActions={
        <Tooltip content={showInfo ? 'Hide help text' : 'Show help text'}>
          <button
            onClick={() => setShowInfo(!showInfo)}
            className={`p-1.5 rounded-lg transition-colors ${
              showInfo 
                ? 'bg-gray-900 text-white dark:bg-white dark:text-gray-900'
                : 'bg-gray-100 text-gray-500 dark:bg-gray-800 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700'
            }`}
          >
            <Info className="w-4 h-4" />
          </button>
        </Tooltip>
      }
      actions={
        <div className="flex items-center justify-between w-full">
          <label className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-300 cursor-pointer">
            <input
              type="checkbox"
              checked={rememberSettings}
              onChange={(e) => setRememberSettings(e.target.checked)}
              className="rounded border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100 focus:ring-0"
            />
            Remember my settings
          </label>
          <ModalActions
            onClose={handleClose}
            onApply={handleSave}
            isDirty={isDirty}
          />
        </div>
      }
    >
      <div className="flex h-full">
        {/* Sidebar */}
        <div className="w-56 shrink-0 border-r border-gray-200 dark:border-gray-800">
          {TABS.map((tab) => {
            const Icon = tab.icon;
            return (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`w-full text-left p-3 transition-colors ${
                  activeTab === tab.id
                    ? 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100'
                    : 'hover:bg-gray-50 dark:hover:bg-gray-800/50 text-gray-600 dark:text-gray-400'
                }`}
              >
                <div className="flex items-center gap-2">
                  <Icon className="w-4 h-4" />
                  <span className="font-medium text-sm">{tab.name}</span>
                </div>
                <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                  {tab.description}
                </p>
              </button>
            );
          })}
        </div>

        {/* Content */}
        <div className="flex-1 p-6 overflow-y-auto">
          {renderContent()}
        </div>
      </div>
    </BaseModal>
  );
}