export function arrayBufferToBase64(buffer: ArrayBuffer): string {
  const binary = new Uint8Array(buffer).reduce(
    (data, byte) => data + String.fromCharCode(byte),
    ''
  );
  return btoa(binary);
}

export function createDataUrl(arrayBuffer: ArrayBuffer, mimeType: string): string {
  const base64 = arrayBufferToBase64(arrayBuffer);
  return `data:${mimeType};base64,${base64}`;
}

export async function loadFontData(file: File): Promise<{ arrayBuffer: ArrayBuffer; base64: string }> {
  const arrayBuffer = await file.arrayBuffer();
  const base64 = arrayBufferToBase64(arrayBuffer);
  return { arrayBuffer, base64 };
}