import React from 'react';
import type { TextAlignment } from '../types';

interface Props {
  headingFont: string;
  bodyFont: string;
  primarySize: number;
  secondarySize: number;
  textAlignment: TextAlignment;
  getLineHeight: (size: number) => number;
  content: any;
  theme: 'light' | 'dark';
}

export function FeaturesSection({
  headingFont,
  bodyFont,
  primarySize,
  secondarySize,
  textAlignment,
  getLineHeight,
  content,
  theme
}: Props) {
  return (
    <div className={`py-16 transition-colors ${
      theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'
    }`}>
      <div className="max-w-6xl mx-auto px-8">
        <div className="grid grid-cols-3 gap-12">
          {content.features.map(({ title, description }, i) => (
            <div key={title} className="space-y-4">
              <div className={`w-12 h-12 rounded-lg shadow-sm flex items-center justify-center ${
                theme === 'dark' ? 'bg-gray-900' : 'bg-white'
              }`}>
                <span className={`text-xl font-mono ${
                  theme === 'dark' ? 'text-gray-400' : 'text-gray-500'
                }`}>{i + 1}</span>
              </div>
              <h3 
                className={theme === 'dark' ? 'text-white' : 'text-gray-900'}
                style={{ 
                  fontFamily: headingFont,
                  fontSize: `${Math.round(primarySize * 0.5)}px`,
                  lineHeight: getLineHeight(Math.round(primarySize * 0.5)),
                  textAlign: textAlignment
                }}
              >
                {title}
              </h3>
              <p 
                className={theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}
                style={{ 
                  fontFamily: bodyFont,
                  fontSize: `${secondarySize}px`,
                  lineHeight: getLineHeight(secondarySize),
                  textAlign: textAlignment
                }}
              >
                {description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}