import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ErrorBoundary } from './utils/errorBoundary';
import { useThemeStore } from './store/themeStore';
import './index.css';

// Initialize theme from storage
const theme = useThemeStore.getState().theme;
document.documentElement.classList.add(theme);

const root = document.getElementById('root');
if (!root) throw new Error('Root element not found');

createRoot(root).render(
  <StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </StrictMode>
);