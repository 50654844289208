import React from 'react';
import { generateTypeSpecimen } from '../../../../utils/typeSpecimen';

interface Props {
  font: string;
}

export function Layout4({ font }: Props) {
  const specimen = React.useMemo(() => generateTypeSpecimen(), []);
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  return (
    <div className="w-full max-w-[1400px] mx-auto p-12">
      {/* Hero Section */}
      <div className="mb-32 text-center">
        <h1 
          className="text-[100px] leading-[0.9] text-gray-900 dark:text-gray-100 mb-12"
          style={{ fontFamily: font }}
        >
          {specimen.displayText}
        </h1>
        <p 
          className="text-3xl leading-relaxed text-gray-600 dark:text-gray-400"
          style={{ fontFamily: font }}
        >
          {specimen.subtitle}
        </p>
      </div>

      {/* Character Set */}
      <div className="mb-32">
        <h2 className="text-sm font-medium text-gray-400 dark:text-gray-500 mb-8">
          Character Set
        </h2>
        <div className="grid grid-cols-26 gap-3">
          {chars.map((char) => (
            <div
              key={char}
              className="aspect-square flex items-center justify-center border border-gray-200 dark:border-gray-800 rounded-lg bg-white/50 dark:bg-gray-800/50 backdrop-blur-sm"
            >
              <span 
                className="text-4xl leading-none text-gray-900 dark:text-gray-100"
                style={{ fontFamily: font }}
              >
                {char}
              </span>
            </div>
          ))}
        </div>
      </div>

      {/* Text Sizes */}
      <div className="grid grid-cols-2 gap-32">
        <div>
          <h2 className="text-sm font-medium text-gray-400 dark:text-gray-500 mb-12">
            Size Scale
          </h2>
          <div className="space-y-16">
            {specimen.sizes.map(({ size, text }) => (
              <div key={size}>
                <span className="text-xs font-mono text-gray-400 dark:text-gray-500 block mb-3">
                  {size}px
                </span>
                <p 
                  className="text-gray-900 dark:text-gray-100 truncate"
                  style={{ 
                    fontFamily: font, 
                    fontSize: `${size}px`,
                    lineHeight: '1.2'
                  }}
                >
                  {text}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div>
          <h2 className="text-sm font-medium text-gray-400 dark:text-gray-500 mb-12">
            Sample Text
          </h2>
          <div className="space-y-12">
            {specimen.paragraphs.map((text, index) => (
              <p
                key={index}
                className="text-lg leading-relaxed text-gray-900 dark:text-gray-100"
                style={{ fontFamily: font }}
              >
                {text}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}