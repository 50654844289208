import React from 'react';
import { useGridStore } from '../../store/gridStore';

interface Props {
  className?: string;
  size?: number;
  opacity?: number;
  fixed?: boolean;
  scroll?: boolean;
}

export function GridBackground({ 
  className = '', 
  size = 24, 
  opacity = 0.1,
  fixed = false,
  scroll = false
}: Props) {
  const { showGrid } = useGridStore();

  if (!showGrid) {
    return null;
  }

  return (
    <div 
      className={`${fixed ? 'fixed' : 'absolute'} inset-0 ${className}`}
      style={{
        backgroundImage: `
          linear-gradient(to right, currentColor 0.5px, transparent 0.5px),
          linear-gradient(to bottom, currentColor 0.5px, transparent 0.5px)
        `,
        backgroundSize: `${size}px ${size}px`,
        backgroundAttachment: scroll ? 'scroll' : fixed ? 'fixed' : 'local',
        opacity: opacity,
        pointerEvents: 'none'
      }}
      aria-hidden="true"
    />
  );
}