import { UnicodeRange } from '../types/font';

export const UNICODE_RANGES = [
  { 
    id: 'basic-latin', 
    name: 'Basic Latin', 
    range: 'U+0020-007F',
    description: 'Standard ASCII characters'
  },
  { 
    id: 'latin-1', 
    name: 'Latin-1 Supplement', 
    range: 'U+00A0-00FF',
    description: 'Western European characters'
  },
  { 
    id: 'latin-extended-a', 
    name: 'Latin Extended-A', 
    range: 'U+0100-017F',
    description: 'Additional European characters'
  },
  { 
    id: 'greek', 
    name: 'Greek', 
    range: 'U+0370-03FF',
    description: 'Greek alphabet and symbols'
  },
  { 
    id: 'cyrillic', 
    name: 'Cyrillic', 
    range: 'U+0400-04FF',
    description: 'Cyrillic alphabet'
  },
  { 
    id: 'punctuation', 
    name: 'General Punctuation', 
    range: 'U+2000-206F',
    description: 'Advanced punctuation and spaces'
  },
  { 
    id: 'symbols', 
    name: 'Symbols and Arrows', 
    range: 'U+2190-21FF',
    description: 'Arrows and mathematical symbols'
  },
  { 
    id: 'math', 
    name: 'Mathematical Operators', 
    range: 'U+2200-22FF',
    description: 'Mathematical and logical operators'
  }
] as const;

export function parseUnicodeRange(range: string): UnicodeRange {
  const [start, end] = range.replace('U+', '').split('-').map(hex => parseInt(hex, 16));
  return { start, end };
}

export function formatUnicodeRange(range: UnicodeRange): string {
  return `U+${range.start.toString(16).padStart(4, '0').toUpperCase()}-${range.end.toString(16).padStart(4, '0').toUpperCase()}`;
}

export function getRangeDescription(range: string): string {
  const rangeData = UNICODE_RANGES.find(r => r.range === range);
  return rangeData?.description || '';
}

export function isRangeSupported(range: string, supportedRanges: string[]): boolean {
  return supportedRanges.includes(range);
}