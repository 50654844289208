import React from 'react';
import type { TextAlignment } from '../types';

interface Props {
  headingFont: string;
  bodyFont: string;
  primarySize: number;
  secondarySize: number;
  textAlignment: TextAlignment;
  getLineHeight: (size: number) => number;
  content: any;
  theme: 'light' | 'dark';
}

export function CTASection({
  headingFont,
  bodyFont,
  primarySize,
  secondarySize,
  textAlignment,
  getLineHeight,
  content,
  theme
}: Props) {
  return (
    <div className={`py-16 transition-colors ${
      theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'
    }`}>
      <div className="max-w-2xl mx-auto px-8">
        <h2 
          className={`mb-6 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}
          style={{ 
            fontFamily: headingFont,
            fontSize: `${Math.round(primarySize * 0.7)}px`,
            lineHeight: getLineHeight(Math.round(primarySize * 0.7)),
            textAlign: textAlignment
          }}
        >
          {content.cta.title}
        </h2>
        <p 
          className={`mb-8 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}
          style={{ 
            fontFamily: bodyFont,
            fontSize: `${secondarySize}px`,
            lineHeight: getLineHeight(secondarySize),
            textAlign: textAlignment
          }}
        >
          {content.cta.description}
        </p>
        <div className={`flex gap-4 ${
          textAlignment === 'center' ? 'justify-center' : 
          textAlignment === 'right' ? 'justify-end' : 'justify-start'
        }`}>
          <button 
            className={`px-6 py-3 rounded-lg transition-colors ${
              theme === 'dark'
                ? 'bg-white text-gray-900 hover:bg-gray-100'
                : 'bg-gray-900 text-white hover:bg-black'
            }`}
            style={{ 
              fontFamily: headingFont,
              fontSize: `${secondarySize}px`
            }}
          >
            Initialize
          </button>
          <button 
            className={`px-6 py-3 rounded-lg border transition-colors ${
              theme === 'dark'
                ? 'border-gray-700 text-gray-300 hover:bg-gray-700'
                : 'border-gray-200 text-gray-600 hover:bg-gray-50'
            }`}
            style={{ 
              fontFamily: headingFont,
              fontSize: `${secondarySize}px`
            }}
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
}