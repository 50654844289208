import React from 'react';
import { Check } from 'lucide-react';
import { useSettingsStore } from '../../store/settingsStore';
import { FileFormatIcon } from '../icons/FileFormatIcon';
import { useStepProgress } from '../../hooks/useStepProgress';

const FORMATS = [
  { id: 'WOFF2', name: 'WOFF2', description: 'Best compression, modern browsers' },
  { id: 'WOFF', name: 'WOFF', description: 'Good compression, wide support' },
  { id: 'TTF', name: 'TTF', description: 'TrueType format, legacy support' },
  { id: 'EOT', name: 'EOT', description: 'Legacy IE support' }
] as const;

interface Props {
  fontId: string;
}

export function FormatSelector({ fontId }: Props) {
  const { completeStep } = useStepProgress();
  const { updateSettings, getSettings } = useSettingsStore();
  const settings = getSettings(fontId);

  const handleFormatChange = (format: string, checked: boolean) => {
    const newFormats = checked 
      ? [...settings.formats, format]
      : settings.formats.filter(f => f !== format);

    // Ensure at least one format is selected
    if (newFormats.length > 0) {
      updateSettings(fontId, { formats: newFormats });
      completeStep(2);
    }
  };

  return (
    <div>
      <label className="text-xs font-medium text-gray-600 dark:text-gray-400 mb-2 block">
        Output Formats
      </label>
      <div className="grid grid-cols-4 gap-2">
        {FORMATS.map((format) => (
          <label
            key={format.id}
            className="relative flex items-center justify-center"
          >
            <input
              type="checkbox"
              className="peer sr-only"
              checked={settings.formats.includes(format.id)}
              onChange={(e) => handleFormatChange(format.id, e.target.checked)}
            />
            <div className={`relative w-full flex items-center justify-center p-2 rounded-lg border transition-all cursor-pointer ${
              settings.formats.includes(format.id)
                ? 'bg-gray-900 dark:bg-white border-gray-900 dark:border-white'
                : 'border-gray-200 dark:border-dark-border hover:bg-gray-50 dark:hover:bg-gray-800/50'
            }`}>
              <FileFormatIcon 
                format={format.id} 
                size="md" 
                className={settings.formats.includes(format.id) ? 'text-white dark:text-gray-900' : ''}
              />
              {settings.formats.includes(format.id) && (
                <div className="absolute top-1 right-1">
                  <Check className="w-3 h-3 text-white dark:text-gray-900" />
                </div>
              )}
            </div>
            <div className="absolute -bottom-6 left-1/2 -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
              <div className="px-2 py-1 text-xs bg-gray-900 dark:bg-white text-white dark:text-gray-900 rounded whitespace-nowrap">
                {format.description}
              </div>
            </div>
          </label>
        ))}
      </div>
    </div>
  );
}