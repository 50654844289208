import React from 'react';

interface Props {
  isVisible: boolean;
}

export function DragOverlay({ isVisible }: Props) {
  return (
    <div className={`absolute inset-0 pointer-events-none transition-all duration-300 ${
      isVisible ? 'opacity-100' : 'opacity-0'
    }`}>
      {/* Border highlight only */}
      <div className="absolute inset-0 rounded-xl border-2 border-dashed border-gray-400 dark:border-gray-500 transition-colors" />
    </div>
  );
}