import React from 'react';
import { TextCursor, Layout } from 'lucide-react';

interface Props {
  mode: 'edit' | 'specimen';
  onModeChange: (mode: 'edit' | 'specimen') => void;
}

export function ViewModeToggle({ mode, onModeChange }: Props) {
  return (
    <div className="flex gap-0.5 p-0.5 bg-gray-100 dark:bg-gray-800 rounded-lg">
      <button
        onClick={() => onModeChange('edit')}
        className={`flex items-center gap-1.5 px-3 py-1.5 rounded transition-colors ${
          mode === 'edit'
            ? 'bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-200 shadow-sm'
            : 'text-gray-500 dark:text-gray-400 hover:bg-white/50 dark:hover:bg-gray-600/50'
        }`}
        title="Text Editor"
      >
        <TextCursor className="w-4 h-4" />
        <span className="text-sm font-medium hidden lg:inline">Editor</span>
      </button>
      <button
        onClick={() => onModeChange('specimen')}
        className={`flex items-center gap-1.5 px-3 py-1.5 rounded transition-colors ${
          mode === 'specimen'
            ? 'bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-200 shadow-sm'
            : 'text-gray-500 dark:text-gray-400 hover:bg-white/50 dark:hover:bg-gray-600/50'
        }`}
        title="Type Specimen"
      >
        <Layout className="w-4 h-4" />
        <span className="text-sm font-medium hidden lg:inline">Type Specimen</span>
      </button>
    </div>
  );
}