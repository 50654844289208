import React from 'react';
import type { FontFile } from '../../types/font';
import { UnicodeCharacterMap } from '../unicode/UnicodeCharacterMap';

interface Props {
  font?: FontFile;
  fontLoaded: boolean;
  onFontSelect?: (id: string) => void;
  fonts: FontFile[];
}

export function GlyphPreview({ font, fontLoaded, onFontSelect, fonts }: Props) {
  if (!font) {
    return (
      <div className="flex items-center justify-center p-6 bg-gray-50 dark:bg-gray-800/50 rounded-lg border border-gray-200 dark:border-dark-border h-full">
        <p className="text-sm text-gray-500 dark:text-gray-400">
          Upload a font to preview characters
        </p>
      </div>
    );
  }

  return (
    <div className="h-full bg-gray-50 dark:bg-gray-800/50 rounded-lg border border-gray-200 dark:border-dark-border">
      <UnicodeCharacterMap
        font={font}
        fonts={fonts}
        fontLoaded={fontLoaded}
        onFontSelect={onFontSelect}
      />
    </div>
  );
}