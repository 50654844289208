import React, { memo } from 'react';
import { FontSettingsPanel } from './settings/FontSettingsPanel';
import type { FontFile, FontSettingsUpdateFn } from '../types/font';
import { StepHeading } from './common/StepHeading';
import { AdvancedSettingsModal } from './modals/AdvancedSettingsModal';

interface Props {
  fonts: FontFile[];
  onSettingsChange: FontSettingsUpdateFn;
  onFontRemove?: (id: string) => void;
}

export const QuickSettings = memo(function QuickSettings({ 
  fonts, 
  onSettingsChange,
  onFontRemove,
}: Props) {
  const [selectedFont, setSelectedFont] = React.useState<FontFile | undefined>();

  return (
    <>
      <div className="space-y-4">
        <div className="bg-white dark:bg-dark-surface rounded-xl border border-gray-200 dark:border-dark-border shadow-container dark:shadow-container-dark">
          <div className="p-4 bg-gray-50 dark:bg-gray-800/50 border-b border-gray-200 dark:border-dark-border rounded-t-xl">
            <StepHeading step={2} title="" />
          </div>

          {fonts.length > 0 ? (
            <div className="p-4 space-y-4">
              {fonts.map((font) => (
                <FontSettingsPanel
                  key={font.id}
                  font={font}
                  onSettingsChange={onSettingsChange}
                  onRemove={onFontRemove}
                  onAdvancedClick={() => setSelectedFont(font)}
                />
              ))}
            </div>
          ) : (
            <div className="p-8 text-center">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Upload a font to configure output settings
              </p>
            </div>
          )}
        </div>
      </div>

      <AdvancedSettingsModal
        isOpen={!!selectedFont}
        onClose={() => setSelectedFont(undefined)}
        font={selectedFont}
      />
    </>
  );
});