import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface GridState {
  showGrid: boolean;
  toggleGrid: () => void;
  setShowGrid: (show: boolean) => void;
}

export const useGridStore = create<GridState>()(
  persist(
    (set) => ({
      showGrid: true,
      toggleGrid: () => set((state) => ({ showGrid: !state.showGrid })),
      setShowGrid: (show) => set({ showGrid: show }),
    }),
    {
      name: 'grid-storage',
    }
  )
);