import type { FontSettings } from '../types/font';

export const DEFAULT_SETTINGS: FontSettings = {
  opentype: {
    flatten: false,
    features: []
  },
  optimization: {
    hinting: true,
    compression: 'balanced'
  },
  rendering: {
    gaspTable: 'keep',
    kerning: 'keep'
  },
  metrics: {
    mode: 'auto',
    xHeightScale: 100
  },
  formats: ['WOFF2'],
  subset: 'basic',
  subsettingOptions: {
    encoding: 'macroman',
    characterTypes: [],
    languages: [],
    unicodeTables: []
  },
  features: [],
  cssFilename: '',
  fontFamily: '',
  fontDisplay: 'swap',
  embedBase64: false,
  styleLink: true,
  styleOutput: 'separate'
};