import React from 'react';
import { ThemeToggle } from './ThemeToggle';
import { StepsHeader } from './StepsHeader';

export function Header() {
  return (
    <header className="fixed top-0 left-0 right-0 z-30">
      <div className="bg-white/95 dark:bg-dark-surface/95 backdrop-blur-sm border-b border-gray-200 dark:border-gray-800">
        <div className="max-w-[800px] mx-auto px-4 py-3 flex items-center justify-between">
          <a 
            href="https://font.zip" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="flex items-center"
          >
            <img 
              src="https://fontzip.nyc3.cdn.digitaloceanspaces.com/fontzip%20logo.png" 
              alt="font.zip logo" 
              className="h-8 w-auto"
            />
          </a>
          
          <div className="absolute left-1/2 -translate-x-1/2 hidden xs:block">
            <StepsHeader />
          </div>

          <div className="relative z-10">
            <ThemeToggle />
          </div>
        </div>
      </div>
    </header>
  );
}