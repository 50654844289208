import React, { memo } from 'react';
import type { FontFile } from '../types/font';
import { SingleFontPreview } from './preview/SingleFontPreview';
import { StepHeading } from './common/StepHeading';

interface Props {
  fonts: FontFile[];
  onFontRemove?: (id: string) => void;
}

export const FontPreview = memo(function FontPreview({ fonts, onFontRemove }: Props) {
  return (
    <div className="space-y-4" data-step="3">
      <div className="bg-white dark:bg-dark-surface rounded-xl border border-gray-200 dark:border-dark-border shadow-container dark:shadow-container-dark">
        <div className="p-4 bg-gray-50 dark:bg-gray-800/50 border-b border-gray-200 dark:border-dark-border rounded-t-xl">
          <StepHeading step={3} title="" />
        </div>

        {fonts.length > 0 ? (
          <div className="p-4 space-y-6">
            {fonts.map((font) => (
              <SingleFontPreview 
                key={font.id} 
                font={font} 
                onRemove={onFontRemove}
              />
            ))}
          </div>
        ) : (
          <div className="p-8 text-center">
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Upload a font to preview
            </p>
          </div>
        )}
      </div>
    </div>
  );
});