import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import type { FontFile } from '../../types/font';
import { formatUnicode } from '../../utils/unicodeUtils';
import { getCharacterName } from '../../utils/unicodeData';
import { GridBackground } from '../common/GridBackground';
import { CopyableCode } from '../common/CopyableCode';

interface Props {
  font: FontFile;
  fontLoaded: boolean;
  currentChar: number;
  range: { start: number; end: number };
  onCharSelect: (charCode: number) => void;
  onNavigate: (direction: 'prev' | 'next') => void;
}

export function CharacterPreview({ 
  font, 
  fontLoaded, 
  currentChar, 
  range,
  onCharSelect,
  onNavigate 
}: Props) {
  const [isHovering, setIsHovering] = React.useState(false);
  const [copiedField, setCopiedField] = React.useState<string | null>(null);

  const handleCopy = async (text: string, field: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedField(field);
      setTimeout(() => setCopiedField(null), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <div className="h-[400px] flex flex-col items-center justify-between space-y-6">
      {/* Character Name */}
      <div className="text-center w-full">
        <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">
          {getCharacterName(currentChar)}
        </h3>
      </div>

      {/* Main Character Display */}
      <div className="relative flex-1 flex items-center justify-center w-full">
        <div className="relative w-72 h-72 mx-auto">
          <div 
            className="absolute inset-0 flex items-center justify-center bg-white dark:bg-dark-surface rounded-md border border-gray-200 dark:border-dark-border shadow-sm overflow-hidden"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <div className="absolute inset-0">
              <GridBackground className="text-gray-900 dark:text-gray-100" />
            </div>
            <span
              className="relative text-[220px] leading-none select-none text-gray-900 dark:text-gray-100 transition-all duration-200"
              style={{
                fontFamily: fontLoaded && font ? `PreviewFont-${font.id}` : 'inherit',
                transform: isHovering ? 'scale(1.05)' : 'scale(1)'
              }}
            >
              {String.fromCharCode(currentChar)}
            </span>
          </div>

          <button
            onClick={() => onNavigate('prev')}
            className="absolute -left-3 top-1/2 -translate-y-1/2 p-2 text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 transition-colors bg-white dark:bg-dark-surface rounded-full shadow-sm border border-gray-200 dark:border-dark-border hover:scale-110 active:scale-95"
          >
            <ChevronLeft className="w-4 h-4" />
          </button>

          <button
            onClick={() => onNavigate('next')}
            className="absolute -right-3 top-1/2 -translate-y-1/2 p-2 text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 transition-colors bg-white dark:bg-dark-surface rounded-full shadow-sm border border-gray-200 dark:border-dark-border hover:scale-110 active:scale-95"
          >
            <ChevronRight className="w-4 h-4" />
          </button>
        </div>
      </div>

      {/* Character Info */}
      <div className="w-full grid grid-cols-3 gap-4">
        <CopyableCode 
          label="Unicode"
          value={formatUnicode(currentChar)}
        />
        <CopyableCode 
          label="HTML"
          value={`&#${currentChar};`}
        />
        <CopyableCode 
          label="Decimal"
          value={currentChar.toString()}
        />
      </div>
    </div>
  );
}