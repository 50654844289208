import React, { useEffect } from 'react';
import { Check } from 'lucide-react';

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

export function DownloadNotification({ isVisible, onClose }: Props) {
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        onClose();
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-8 left-1/2 -translate-x-1/2 z-[1000]">
      <div className="flex items-center gap-3 px-6 py-4 bg-gray-900 dark:bg-white text-white dark:text-gray-900 rounded-lg shadow-lg animate-fade-in">
        <Check className="w-5 h-5" />
        <p className="text-sm font-medium">Font package downloaded successfully</p>
      </div>
    </div>
  );
}