import React from 'react';
import { Info } from 'lucide-react';
import type { FontFile, FontSettings } from '../../types/font';
import { DEFAULT_SETTINGS } from '../../constants/defaultSettings';
import { Tooltip } from '../common/Tooltip';

interface Props {
  font: FontFile;
  settings: FontSettings;
}

function calculateOutputSize(originalSize: number, settings: FontSettings): number {
  let totalSize = originalSize;

  // Get compression ratio based on optimization level
  const compressionRatios = {
    maximum: 0.4,
    balanced: 0.6,
    speed: 0.8,
    none: 1.0
  };

  const compressionRatio = compressionRatios[settings.optimization?.compression || DEFAULT_SETTINGS.optimization.compression];
  totalSize *= compressionRatio;

  // Apply format-specific compression
  settings.formats.forEach(format => {
    switch (format) {
      case 'WOFF2':
        totalSize *= 0.6; // ~40% reduction
        break;
      case 'WOFF':
        totalSize *= 0.7; // ~30% reduction
        break;
      case 'TTF':
      case 'EOT':
        totalSize *= 0.9; // ~10% reduction
        break;
    }
  });

  // Apply hinting reduction if disabled
  if (!settings.optimization?.hinting) {
    totalSize *= 0.9; // ~10% reduction
  }

  return Math.round(totalSize);
}

export function OutputSizeInfo({ font, settings }: Props) {
  const originalSize = font.size;
  const estimatedSize = calculateOutputSize(originalSize, settings);
  const reduction = Math.round(((originalSize - estimatedSize) / originalSize) * 100);

  // Calculate individual format sizes
  const formatSizes = settings.formats.map(format => {
    const size = calculateOutputSize(originalSize, { 
      ...DEFAULT_SETTINGS,
      ...settings,
      formats: [format] 
    });
    return { format, size };
  });

  const tooltipContent = (
    <div className="space-y-2 p-1">
      <div className="flex items-center justify-between">
        <span className="text-gray-300 dark:text-gray-600">Original Size</span>
        <span className="font-medium">{Math.round(originalSize / 1024)}KB</span>
      </div>
      
      <div className="border-t border-gray-800 dark:border-gray-200 pt-2">
        <div className="text-xs text-gray-400 dark:text-gray-500 mb-1">Estimated Output</div>
        {formatSizes.map(({ format, size }) => (
          <div key={format} className="flex items-center justify-between text-sm">
            <span className="text-gray-300 dark:text-gray-600">{format}</span>
            <span>{Math.round(size / 1024)}KB</span>
          </div>
        ))}
      </div>

      {reduction > 0 && (
        <div className="border-t border-gray-800 dark:border-gray-200 pt-2 flex items-center justify-between">
          <span className="text-gray-300 dark:text-gray-600">Total Reduction</span>
          <span className="text-green-400 dark:text-green-600">
            -{reduction}%
          </span>
        </div>
      )}
    </div>
  );

  return (
    <Tooltip content={tooltipContent}>
      <button className="group flex items-center gap-2 px-3 py-1.5 bg-gray-50 dark:bg-gray-800/50 rounded-lg border border-gray-200 dark:border-dark-border hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors">
        <div className="flex items-baseline gap-2">
          <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
            {Math.round(estimatedSize / 1024)}KB
          </span>
          {reduction > 0 && (
            <span className="text-xs text-green-600 dark:text-green-400">
              -{reduction}%
            </span>
          )}
        </div>
        <Info className="w-3.5 h-3.5 text-gray-400 dark:text-gray-500 group-hover:text-gray-600 dark:group-hover:text-gray-300" />
      </button>
    </Tooltip>
  );
}