import React from 'react';
import { SpecimenLayout } from './layouts/SpecimenLayout';
import { GridBackground } from '../common/GridBackground';

interface Props {
  font: string;
  layout: number;
  className?: string;
  hideGrid?: boolean;
}

export function TypeSpecimen({ font, layout, className = '', hideGrid = false }: Props) {
  return (
    <div className={`relative h-full bg-white dark:bg-dark-surface ${className}`}>
      {!hideGrid && (
        <div className="absolute inset-0">
          <GridBackground className="text-gray-900 dark:text-gray-100" scroll />
        </div>
      )}
      
      <div className="relative h-full overflow-y-auto">
        <SpecimenLayout font={font} layout={layout} />
      </div>
    </div>
  );
}