import React from 'react';

interface Props {
  onEncodingChange: (encoding: string) => void;
  onCharacterTypesChange: (types: string[]) => void;
  onLanguagesChange: (languages: string[]) => void;
  onUnicodeTablesChange: (tables: string[]) => void;
  selectedEncoding: string;
  selectedCharacterTypes: string[];
  selectedLanguages: string[];
  selectedUnicodeTables: string[];
}

const CHARACTER_TYPES = [
  'Lowercase',
  'Uppercase',
  'Numbers',
  'Punctuation',
  'Currency',
  'Typographics',
  'Math Symbols',
  'Alt Punctuation',
  'Lower Accents',
  'Upper Accents',
  'Diacriticals'
];

const LANGUAGES = [
  'Albanian', 'Bosnian', 'Catalan', 'Croatian', 'Cyrillic', 'Czech',
  'Danish', 'Dutch', 'English', 'Esperanto', 'Estonian', 'Faroese',
  'French', 'Georgian', 'German', 'Greek', 'Hebrew', 'Hungarian',
  'Icelandic', 'Italian', 'Latvian', 'Lithuanian', 'Malagasy',
  'Maltese', 'Norwegian', 'Polish', 'Portuguese', 'Romanian',
  'Serbian', 'Slovak', 'Slovenian', 'Spanish', 'Swedish', 'Turkish'
];

const UNICODE_TABLES = [
  'Basic Latin',
  'Latin-1 Sup',
  'Currency Symbols',
  'Punctuation',
  'Latin Extended-A',
  'Latin Extended-B',
  'Latin Extended +'
];

export function CustomSubsettingOptions({
  onEncodingChange,
  onCharacterTypesChange,
  onLanguagesChange,
  onUnicodeTablesChange,
  selectedEncoding,
  selectedCharacterTypes,
  selectedLanguages,
  selectedUnicodeTables
}: Props) {
  return (
    <div className="space-y-8">
      {/* Character Encoding */}
      <div>
        <label className="block text-sm font-medium text-gray-900 dark:text-gray-100 mb-2">
          Character Encoding
        </label>
        <select
          value={selectedEncoding}
          onChange={(e) => onEncodingChange(e.target.value)}
          className="w-full px-3 py-2 text-sm rounded-lg border border-gray-200 dark:border-dark-border bg-transparent text-gray-900 dark:text-gray-100 focus:ring-0 focus:border-gray-900 dark:focus:border-white"
        >
          <option value="macroman">Mac Roman</option>
        </select>
      </div>

      {/* Character Types */}
      <div>
        <label className="block text-sm font-medium text-gray-900 dark:text-gray-100 mb-2">
          Character Types
        </label>
        <div className="grid grid-cols-2 gap-2">
          {CHARACTER_TYPES.map(type => (
            <label
              key={type}
              className="flex items-center gap-2"
            >
              <input
                type="checkbox"
                checked={selectedCharacterTypes.includes(type)}
                onChange={(e) => {
                  const newTypes = e.target.checked
                    ? [...selectedCharacterTypes, type]
                    : selectedCharacterTypes.filter(t => t !== type);
                  onCharacterTypesChange(newTypes);
                }}
                className="rounded border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100 focus:ring-0"
              />
              <span className="text-sm text-gray-700 dark:text-gray-300">
                {type}
              </span>
            </label>
          ))}
        </div>
      </div>

      {/* Languages */}
      <div>
        <label className="block text-sm font-medium text-gray-900 dark:text-gray-100 mb-2">
          Languages
        </label>
        <div className="grid grid-cols-3 gap-2 max-h-48 overflow-y-auto">
          {LANGUAGES.map(lang => (
            <label
              key={lang}
              className="flex items-center gap-2"
            >
              <input
                type="checkbox"
                checked={selectedLanguages.includes(lang)}
                onChange={(e) => {
                  const newLangs = e.target.checked
                    ? [...selectedLanguages, lang]
                    : selectedLanguages.filter(l => l !== lang);
                  onLanguagesChange(newLangs);
                }}
                className="rounded border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100 focus:ring-0"
              />
              <span className="text-sm text-gray-700 dark:text-gray-300">
                {lang}
              </span>
            </label>
          ))}
        </div>
      </div>

      {/* Unicode Tables */}
      <div>
        <label className="block text-sm font-medium text-gray-900 dark:text-gray-100 mb-2">
          Unicode Tables
        </label>
        <div className="grid grid-cols-2 gap-2">
          {UNICODE_TABLES.map(table => (
            <label
              key={table}
              className="flex items-center gap-2"
            >
              <input
                type="checkbox"
                checked={selectedUnicodeTables.includes(table)}
                onChange={(e) => {
                  const newTables = e.target.checked
                    ? [...selectedUnicodeTables, table]
                    : selectedUnicodeTables.filter(t => t !== table);
                  onUnicodeTablesChange(newTables);
                }}
                className="rounded border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100 focus:ring-0"
              />
              <span className="text-sm text-gray-700 dark:text-gray-300">
                {table}
              </span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
}