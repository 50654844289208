import React from 'react';
import { Plus } from 'lucide-react';
import { FileListItem } from './FileListItem';
import { ProcessingStatus } from './ProcessingStatus';
import type { FontFile } from '../../types/font';

interface Props {
  files: FontFile[];
  selectedFontId?: string | null;
  isUploading: boolean;
  onAddMore: (e: React.MouseEvent) => void;
  onFileSelect?: (id: string) => void;
  onFileRemove: (id: string) => void;
}

export function FileList({
  files,
  selectedFontId,
  isUploading,
  onAddMore,
  onFileSelect,
  onFileRemove
}: Props) {
  return (
    <div className="w-full h-full px-4 py-3">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-4">
          <h4 className="text-xs font-medium text-gray-700 dark:text-gray-300">
            Uploaded Files
          </h4>
          {isUploading && (
            <ProcessingStatus 
              status="Processing fonts" 
              subStatus="Analyzing metadata..." 
            />
          )}
        </div>
        <button
          onClick={onAddMore}
          className="flex items-center gap-1.5 px-3 py-1.5 text-sm font-medium text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-lg transition-colors"
        >
          <Plus className="w-3.5 h-3.5" />
          Add More
        </button>
      </div>

      <div className="space-y-2 max-h-[520px] overflow-y-auto pr-2">
        {files.map((file) => (
          <FileListItem
            key={file.id}
            file={file}
            isSelected={file.id === selectedFontId}
            onSelect={(id) => onFileSelect?.(id)}
            onRemove={onFileRemove}
          />
        ))}
      </div>
    </div>
  );
}