import React, { useState } from 'react';

interface Props {
  value: string;
  label: string;
}

export function CopyableCode({ value, label }: Props) {
  const [isClicked, setIsClicked] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(value);
      setIsClicked(true);
      setTimeout(() => setIsClicked(false), 500);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="text-xs text-gray-500 dark:text-gray-400 mb-2">{label}</div>
      <button
        onClick={handleCopy}
        className="w-full relative group"
      >
        <code className={`block w-full text-sm font-mono px-3 py-1.5 rounded transition-colors ${
          isClicked
            ? 'bg-green-50 dark:bg-green-900/20 text-green-600 dark:text-green-400'
            : 'bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700'
        }`}>
          {value}
        </code>
      </button>
    </div>
  );
}