import React from 'react';
import { LoadingSpinner } from './LoadingSpinner';

interface Props {
  status: string;
  subStatus?: string;
}

export function ProcessingStatus({ status, subStatus }: Props) {
  return (
    <div className="flex items-center gap-3">
      <LoadingSpinner size="sm" className="text-gray-400 dark:text-gray-500" />
      <div>
        <p className="text-sm font-medium text-gray-900 dark:text-white">
          {status}
        </p>
        {subStatus && (
          <p className="text-xs text-gray-500 dark:text-gray-400">
            {subStatus}
          </p>
        )}
      </div>
    </div>
  );
}