import React from 'react';
import { FontSizeSlider } from '../FontSizeSlider';

interface Props {
  size: number;
  scale?: number;
  onSizeChange: (size: number) => void;
  onScaleChange?: (increment: boolean) => void;
  label?: string;
  maxSize?: number;
}

export function FontControls({ 
  size, 
  scale,
  onSizeChange, 
  onScaleChange,
  label = "Text Size",
  maxSize 
}: Props) {
  return (
    <div className="flex items-center gap-3">
      <span className="text-xs text-gray-600 dark:text-gray-400">{label}</span>
      <FontSizeSlider 
        value={size} 
        onChange={onSizeChange}
        maxSize={maxSize}
      />
      {onScaleChange && (
        <div className="flex gap-1">
          <button
            onClick={() => onScaleChange(false)}
            className="px-1.5 py-1 text-xs text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100"
          >
            -
          </button>
          <span className="text-xs text-gray-600 dark:text-gray-400">
            {Math.round(scale! * 100)}%
          </span>
          <button
            onClick={() => onScaleChange(true)}
            className="px-1.5 py-1 text-xs text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100"
          >
            +
          </button>
        </div>
      )}
    </div>
  );
}