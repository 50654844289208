import React, { useState } from 'react';
import { Code, Copy, Check } from 'lucide-react';
import type { FontFile, FontSettings } from '../../types/font';
import { sanitizeFontName } from '../../utils/fontUtils';

interface Props {
  font: FontFile;
  settings: FontSettings;
}

export function CSSPreview({ font, settings }: Props) {
  const [copiedField, setCopiedField] = useState<'css' | 'html' | null>(null);

  const cssContent = `@font-face {
  font-family: '${settings.fontFamily || font.name}';
  src: ${settings.formats.map(format => {
    const ext = format.toLowerCase();
    if (settings.embedBase64) {
      return `url('data:font/${ext};base64,[Base64_Encoded_Font]') format('${ext === 'ttf' ? 'truetype' : ext}')`;
    }
    return `url('${settings.cssFilename || sanitizeFontName(font.name)}.${ext}') format('${ext === 'ttf' ? 'truetype' : ext}')`;
  }).join(',\n       ')};
  font-weight: normal;
  font-style: normal;
  font-display: ${settings.fontDisplay || 'swap'};${settings.opentype?.features?.length ? `
  font-feature-settings: ${settings.opentype.features.map(f => `"${f}" 1`).join(', ')};` : ''}
}`;

  const htmlContent = `<link rel="stylesheet" href="${settings.cssFilename || sanitizeFontName(font.name)}.css">`;

  const handleCopy = async (content: string, field: 'css' | 'html') => {
    try {
      await navigator.clipboard.writeText(content);
      setCopiedField(field);
      setTimeout(() => setCopiedField(null), 2000);
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2">
        <Code className="w-4 h-4 text-gray-400 dark:text-gray-500" />
        <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">
          CSS Output
        </h3>
      </div>

      <div className="p-4 bg-gray-50 dark:bg-gray-800/50 rounded-lg border border-gray-200 dark:border-dark-border space-y-4">
        <div className="relative">
          <pre className="text-xs font-mono bg-white dark:bg-dark-surface p-3 rounded border border-gray-200 dark:border-dark-border overflow-x-auto">
            <code className="text-gray-600 dark:text-gray-400">{cssContent}</code>
          </pre>
          <button
            onClick={() => handleCopy(cssContent, 'css')}
            className="absolute top-2 right-2 p-1.5 text-gray-400 hover:text-gray-900 dark:text-gray-500 dark:hover:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-800 rounded transition-colors"
          >
            {copiedField === 'css' ? (
              <Check className="w-4 h-4" />
            ) : (
              <Copy className="w-4 h-4" />
            )}
          </button>
        </div>

        {settings.styleLink && (
          <>
            <div className="h-px bg-gray-200 dark:bg-gray-700" />
            <div>
              <h4 className="text-xs font-medium text-gray-700 dark:text-gray-300 mb-2">
                HTML Style Link
              </h4>
              <div className="relative">
                <pre className="text-xs font-mono bg-white dark:bg-dark-surface p-3 rounded border border-gray-200 dark:border-dark-border overflow-x-auto">
                  <code className="text-gray-600 dark:text-gray-400">{htmlContent}</code>
                </pre>
                <button
                  onClick={() => handleCopy(htmlContent, 'html')}
                  className="absolute top-2 right-2 p-1.5 text-gray-400 hover:text-gray-900 dark:text-gray-500 dark:hover:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-800 rounded transition-colors"
                >
                  {copiedField === 'html' ? (
                    <Check className="w-4 h-4" />
                  ) : (
                    <Copy className="w-4 h-4" />
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}