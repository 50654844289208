import React from 'react';

interface FontOption {
  name: string;
  stack: string;
}

interface Props {
  selected: FontOption;
  options: FontOption[];
  onSelect: (font: FontOption) => void;
}

export function FontSelector({ selected, options, onSelect }: Props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative w-[120px]" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full px-3 py-2 text-sm bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg text-gray-900 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none"
      >
        <span className="font-medium truncate block">{selected.name}</span>
      </button>

      {isOpen && (
        <div className="absolute bottom-full left-0 right-0 mb-1 py-1 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 z-10">
          {options.map((font) => (
            <button
              key={font.name}
              onClick={() => {
                onSelect(font);
                setIsOpen(false);
              }}
              className="w-full px-3 py-2 text-left hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
            >
              <div className="flex items-center gap-2">
                <div className={`w-1.5 h-1.5 rounded-full transition-colors ${
                  selected.name === font.name 
                    ? 'bg-gray-900 dark:bg-white' 
                    : 'bg-transparent'
                }`} />
                <span className="font-medium text-sm text-gray-900 dark:text-gray-100 truncate">
                  {font.name}
                </span>
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}