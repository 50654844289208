import { useState, useEffect, useRef, useCallback } from 'react';
import type { FontFile } from '../types/font';
import { parseUnicodeRange } from '../utils/unicodeUtils';
import { fontLoader } from '../utils/fontLoader';

const DEFAULT_RANGE = 'U+0020-007F'; // Basic Latin

export function useFontPreview(font?: FontFile) {
  // Start with capital 'A' (Unicode: 65)
  const [currentChar, setCurrentChar] = useState(65);
  const [fontLoaded, setFontLoaded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedRange, setSelectedRange] = useState(DEFAULT_RANGE);
  const mountedRef = useRef(true);
  const abortControllerRef = useRef<AbortController | null>(null);

  // Get supported ranges from font metadata
  const supportedRanges = font?.metadata?.supportedRanges || [DEFAULT_RANGE];

  // Ensure selected range is supported
  useEffect(() => {
    if (font && !supportedRanges.includes(selectedRange)) {
      setSelectedRange(supportedRanges[0] || DEFAULT_RANGE);
    }
  }, [font?.id, supportedRanges, selectedRange]);

  // Parse current range
  const range = parseUnicodeRange(selectedRange || DEFAULT_RANGE);

  // Track component mounted state
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
      abortControllerRef.current?.abort();
    };
  }, []);

  // Handle font loading
  useEffect(() => {
    if (!font) {
      setFontLoaded(false);
      setError(null);
      return;
    }

    // Abort previous load if exists
    abortControllerRef.current?.abort();
    const controller = new AbortController();
    abortControllerRef.current = controller;

    setFontLoaded(false);
    setError(null);

    fontLoader.loadFont(font, { signal: controller.signal })
      .then(() => {
        if (mountedRef.current) {
          setFontLoaded(true);
          setError(null);
        }
      })
      .catch((err) => {
        if (mountedRef.current && !controller.signal.aborted) {
          console.warn('Font loading error:', err);
          setError('Failed to load font. Please try again.');
          setFontLoaded(false);
        }
      });

    return () => {
      controller.abort();
    };
  }, [font?.id]);

  const navigateChar = useCallback((direction: 'prev' | 'next') => {
    setCurrentChar(prev => {
      if (direction === 'prev') {
        return prev > range.start ? prev - 1 : range.end;
      } else {
        return prev < range.end ? prev + 1 : range.start;
      }
    });
  }, [range]);

  return {
    currentChar,
    setCurrentChar,
    fontLoaded,
    error,
    selectedRange,
    setSelectedRange,
    navigateChar,
    supportedRanges,
    range
  };
}