import React from 'react';
import { ChevronUp } from 'lucide-react';
import { SAMPLE_TEXTS } from '../../constants/sampleTexts';

interface Props {
  selectedIndex: number;
  onSelect: (index: number) => void;
  isOpen: boolean;
  onToggle: () => void;
}

export function SampleTextSelector({ 
  selectedIndex, 
  onSelect, 
  isOpen, 
  onToggle 
}: Props) {
  return (
    <div className="relative flex-1">
      <button
        onClick={onToggle}
        className="w-full flex items-center justify-between px-3 py-2 text-sm rounded-lg bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-gray-100 border border-gray-200 dark:border-dark-border hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
      >
        <span className="truncate">
          {SAMPLE_TEXTS[selectedIndex].length > 50 
            ? SAMPLE_TEXTS[selectedIndex].slice(0, 47) + '...' 
            : SAMPLE_TEXTS[selectedIndex]}
        </span>
        <ChevronUp className={`w-4 h-4 text-gray-400 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>

      {isOpen && (
        <div className="absolute bottom-full left-0 right-0 mb-2 py-1 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 max-h-[300px] overflow-y-auto">
          {SAMPLE_TEXTS.map((sample, index) => (
            <button
              key={index}
              onClick={() => onSelect(index)}
              className={`w-full px-3 py-2 text-left text-sm transition-colors ${
                index === selectedIndex
                  ? 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-100'
                  : 'text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700'
              }`}
            >
              {sample.length > 50 ? sample.slice(0, 47) + '...' : sample}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}