import { useState, useCallback, useEffect } from 'react';
import type { FontFile, FontSettings } from '../types/font';
import { useSettingsStore } from '../store/settingsStore';
import { DEFAULT_SETTINGS } from '../constants/defaultSettings';

export function useAdvancedSettings(font?: FontFile) {
  const { getSettings, updateSettings } = useSettingsStore();
  const [tempSettings, setTempSettings] = useState<FontSettings | undefined>();
  const [isDirty, setIsDirty] = useState(false);
  const [initialSettings, setInitialSettings] = useState<FontSettings | undefined>();

  // Load initial settings when font changes
  useEffect(() => {
    if (font) {
      const currentSettings = getSettings(font.id);
      setTempSettings(JSON.parse(JSON.stringify(currentSettings)));
      setInitialSettings(JSON.parse(JSON.stringify(currentSettings)));
      setIsDirty(false);
    }
  }, [font, getSettings]);

  const handleSettingsChange = useCallback((newSettings: Partial<FontSettings>) => {
    setTempSettings(prev => {
      if (!prev) return { ...DEFAULT_SETTINGS, ...newSettings };
      
      const updated = {
        ...prev,
        ...newSettings,
        optimization: {
          ...prev.optimization,
          ...(newSettings.optimization || {})
        },
        rendering: {
          ...prev.rendering,
          ...(newSettings.rendering || {})
        },
        opentype: {
          ...prev.opentype,
          ...(newSettings.opentype || {}),
          features: newSettings.opentype?.features || prev.opentype?.features
        },
        features: newSettings.features || prev.features
      };

      const isChanged = JSON.stringify(updated) !== JSON.stringify(initialSettings);
      setIsDirty(isChanged);
      
      return updated;
    });
  }, [initialSettings]);

  const handleApply = useCallback(() => {
    if (font && isDirty && tempSettings) {
      updateSettings(font.id, tempSettings);
      setInitialSettings(JSON.parse(JSON.stringify(tempSettings)));
      setIsDirty(false);
    }
  }, [font, isDirty, tempSettings, updateSettings]);

  const handleCancel = useCallback(() => {
    if (font && initialSettings) {
      setTempSettings(JSON.parse(JSON.stringify(initialSettings)));
      setIsDirty(false);
    }
  }, [font, initialSettings]);

  return {
    settings: tempSettings,
    isDirty,
    handleSettingsChange,
    handleApply,
    handleCancel
  };
}