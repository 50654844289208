import React from 'react';

interface Props {
  onClose: () => void;
  onApply: () => void;
  isDirty?: boolean;
}

export function ModalActions({ onClose, onApply, isDirty }: Props) {
  return (
    <div className="flex items-center justify-end gap-2">
      <button
        onClick={onClose}
        className="px-3 py-1.5 text-sm text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100"
      >
        Cancel
      </button>
      <button
        onClick={onApply}
        disabled={!isDirty}
        className={`px-3 py-1.5 text-sm rounded-md transition-colors ${
          isDirty
            ? 'text-white bg-gray-900 hover:bg-black dark:bg-white dark:text-gray-900 dark:hover:bg-gray-100'
            : 'text-gray-400 bg-gray-100 dark:text-gray-500 dark:bg-gray-800 cursor-not-allowed'
        }`}
      >
        Apply Changes
      </button>
    </div>
  );
}