import React from 'react';
import { generateTypeSpecimen } from '../../../../utils/typeSpecimen';

interface Props {
  font: string;
}

export function Layout2({ font }: Props) {
  const specimen = React.useMemo(() => generateTypeSpecimen(), []);

  return (
    <div className="w-full h-full grid grid-cols-1 lg:grid-cols-2 max-w-[1400px] mx-auto">
      {/* Left Column */}
      <div className="p-12 lg:p-16 border-b lg:border-b-0 lg:border-r border-gray-200 dark:border-gray-800">
        <div className="sticky top-12">
          <h1 
            className="text-[120px] leading-[0.9] text-gray-900 dark:text-gray-100 mb-12"
            style={{ fontFamily: font }}
          >
            {specimen.displayText}
          </h1>
          <p 
            className="text-2xl leading-relaxed text-gray-600 dark:text-gray-400 max-w-lg"
            style={{ fontFamily: font }}
          >
            {specimen.subtitle}
          </p>
        </div>
      </div>

      {/* Right Column */}
      <div className="p-12 lg:p-16 space-y-32">
        {/* Character Set */}
        <div>
          <h2 className="text-sm font-medium text-gray-400 dark:text-gray-500 mb-12">
            Character Set
          </h2>
          <div 
            className="text-3xl sm:text-4xl leading-[2] text-gray-900 dark:text-gray-100 break-words"
            style={{ fontFamily: font }}
          >
            {specimen.characterSet}
          </div>
        </div>

        {/* Text Sizes */}
        <div>
          <h2 className="text-sm font-medium text-gray-400 dark:text-gray-500 mb-12">
            Size Scale
          </h2>
          <div className="space-y-16">
            {specimen.sizes.map(({ size, text }) => (
              <div key={size} className="flex items-baseline gap-6">
                <span className="text-xs font-mono text-gray-400 dark:text-gray-500 w-12 shrink-0">
                  {size}px
                </span>
                <p 
                  className="text-gray-900 dark:text-gray-100 truncate"
                  style={{ 
                    fontFamily: font, 
                    fontSize: `${size}px`,
                    lineHeight: '1.2'
                  }}
                >
                  {text}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Paragraphs */}
        <div>
          <h2 className="text-sm font-medium text-gray-400 dark:text-gray-500 mb-12">
            Sample Text
          </h2>
          <div className="space-y-12">
            {specimen.paragraphs.map((text, index) => (
              <p
                key={index}
                className="text-lg leading-relaxed text-gray-900 dark:text-gray-100"
                style={{ fontFamily: font }}
              >
                {text}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}