interface Content {
  hero: {
    title: string;
    subtitle: string;
  };
  features: Array<{
    title: string;
    description: string;
  }>;
  content: {
    title: string;
    description: string;
  };
  cta: {
    title: string;
    description: string;
  };
}

const TITLES = [
  'Quantum Flux Matrix',
  'Zenith Wavelength',
  'Cyberjack Protocol',
  'Digital Vortex',
  'Binary Nexus',
  'Hyperquantum Maze',
  'Synthetic Prism',
  'Kinetic Waveform',
  'Xenomorphic Data',
  'Metaphysical Grid'
];

const SUBTITLES = [
  'Explore quantum jumps while visualizing complex data matrices',
  'Synchronize wavelengths through digital consciousness pathways',
  'Unlock cybernetic gateways between physical and virtual worlds',
  'Manifest extraordinary patterns within quantum frameworks',
  'Optimize neural pathways through digital transformation'
];

const FEATURE_TITLES = [
  'Quantum Bridge',
  'Vortex Junction',
  'Wavelength Sync',
  'Kinetic Flux',
  'Xenon Matrix',
  'Cyber Prism',
  'Digital Maze',
  'Neural Nexus',
  'Plasma Grid',
  'Hyper Node'
];

const DESCRIPTIONS = [
  'Quick brown algorithms jump through virtual zero-flux matrices',
  'Synthesize complex wavelengths via quantum bridge networks',
  'Transform physical matter into digital consciousness waves',
  'Visualize extraordinary patterns within cybernetic frameworks',
  'Optimize neural pathways through quantum data synthesis',
  'Manifest kinetic energy through digital transformation grids',
  'Synchronize binary waves across multidimensional spaces',
  'Navigate complex matrices within quantum vortex fields'
];

const CTA_TITLES = [
  'Begin Quantum Jump',
  'Access Vortex Grid',
  'Initialize Matrix',
  'Synchronize Waves',
  'Transform Reality'
];

const CTA_DESCRIPTIONS = [
  'Quick-sync your consciousness with zero-flux quantum matrices',
  'Jump through extraordinary wavelengths of digital existence',
  'Transform physical boundaries through virtual frameworks',
  'Navigate complex pathways within quantum consciousness',
  'Synchronize your mind with digital transformation grids'
];

function getRandomItem<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

function getUniqueItems<T>(array: T[], count: number): T[] {
  const shuffled = [...array].sort(() => Math.random() - 0.5);
  return shuffled.slice(0, count);
}

export function generateRandomContent(): Content {
  return {
    hero: {
      title: getRandomItem(TITLES),
      subtitle: getRandomItem(SUBTITLES)
    },
    features: getUniqueItems(FEATURE_TITLES, 3).map(title => ({
      title,
      description: getRandomItem(DESCRIPTIONS)
    })),
    content: {
      title: getRandomItem(TITLES),
      description: getRandomItem(DESCRIPTIONS)
    },
    cta: {
      title: getRandomItem(CTA_TITLES),
      description: getRandomItem(CTA_DESCRIPTIONS)
    }
  };
}