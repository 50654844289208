import React from 'react';
import type { TextAlignment } from '../types';

interface Props {
  headingFont: string;
  bodyFont: string;
  primarySize: number;
  secondarySize: number;
  textAlignment: TextAlignment;
  getLineHeight: (size: number) => number;
  content: any;
  theme: 'light' | 'dark';
}

export function HeroSection({ 
  headingFont, 
  bodyFont, 
  primarySize, 
  secondarySize,
  textAlignment,
  getLineHeight,
  content,
  theme
}: Props) {
  return (
    <div className={`relative py-16 border-b transition-colors ${
      theme === 'dark' 
        ? 'bg-gray-900 border-gray-800 text-white' 
        : 'bg-white border-gray-100 text-gray-900'
    }`}>
      <div className="max-w-4xl mx-auto px-8">
        <h1 
          className={`mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}
          style={{ 
            fontFamily: headingFont,
            fontSize: `${primarySize}px`,
            lineHeight: getLineHeight(primarySize),
            textAlign: textAlignment
          }}
        >
          {content.hero.title}
        </h1>
        <p 
          className={`max-w-2xl mx-auto ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}
          style={{ 
            fontFamily: bodyFont,
            fontSize: `${secondarySize}px`,
            lineHeight: getLineHeight(secondarySize),
            textAlign: textAlignment
          }}
        >
          {content.hero.subtitle}
        </p>
      </div>
    </div>
  );
}