import React, { useEffect } from 'react';
import { X } from 'lucide-react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  actions?: React.ReactNode;
  headerActions?: React.ReactNode;
}

export function BaseModal({ 
  isOpen, 
  onClose, 
  title, 
  subtitle, 
  children, 
  actions,
  headerActions
}: Props) {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[100] flex items-center justify-center">
      {/* Backdrop */}
      <div 
        className="fixed inset-0 bg-black/60 backdrop-blur-sm"
        onClick={onClose}
      />
      
      {/* Modal */}
      <div className="relative w-full max-w-[800px] h-[560px] bg-white dark:bg-dark-surface rounded-xl shadow-2xl flex flex-col overflow-hidden ring-1 ring-gray-950/10 dark:ring-white/10">
        {/* Content */}
        <div className="relative flex flex-col h-full">
          <div className="shrink-0 p-4 border-b border-gray-200 dark:border-gray-800 flex items-center justify-between bg-white/80 dark:bg-dark-surface/80 backdrop-blur-sm">
            <div>
              <h2 className="text-sm font-medium text-gray-900 dark:text-gray-100">
                {title}
              </h2>
              {subtitle && (
                <p className="text-xs text-gray-500 dark:text-gray-400 mt-0.5">
                  {subtitle}
                </p>
              )}
            </div>

            <div className="flex items-center gap-2">
              {headerActions}
              <button
                onClick={onClose}
                className="p-1 -m-1 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-colors rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
          </div>

          <div className="flex-1 overflow-hidden">
            {children}
          </div>

          {actions && (
            <div className="shrink-0 p-4 border-t border-gray-200 dark:border-gray-800 bg-white/80 dark:bg-dark-surface/80 backdrop-blur-sm">
              {actions}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}