import { getMimeType, getFontFormat } from './mimeTypes';
import { loadFontData, createDataUrl } from './fontData';
import type { FontFile } from '../types/font';

interface LoadOptions {
  timeout?: number;
  signal?: AbortSignal;
}

class FontLoader {
  private loadedFonts = new Map<string, FontFace>();
  private loadingPromises = new Map<string, Promise<void>>();
  private maxRetries = 3;
  private retryDelay = 1000;
  private fontStyles = new Map<string, HTMLStyleElement>();

  private createStyleElement(font: FontFile, dataUrl: string): HTMLStyleElement {
    const style = document.createElement('style');
    style.setAttribute('data-font-id', font.id);
    const format = getFontFormat(font.type);
    
    style.textContent = `
      @font-face {
        font-family: 'PreviewFont-${font.id}';
        src: url('${dataUrl}') format('${format}');
        font-display: block;
      }
    `;
    return style;
  }

  async loadFont(font: FontFile, options: LoadOptions = {}): Promise<void> {
    // Check if already loading
    const existingPromise = this.loadingPromises.get(font.id);
    if (existingPromise) return existingPromise;

    // Check if already loaded
    if (this.loadedFonts.has(font.id)) {
      return Promise.resolve();
    }

    // Create loading promise
    const loadPromise = (async () => {
      let lastError: Error | null = null;

      for (let attempt = 0; attempt < this.maxRetries; attempt++) {
        try {
          // Load font data
          const { arrayBuffer } = await loadFontData(font.originalFile);
          const dataUrl = createDataUrl(arrayBuffer, getMimeType(font.type));

          // Create and insert style element
          const style = this.createStyleElement(font, dataUrl);
          document.head.appendChild(style);
          this.fontStyles.set(font.id, style);

          // Create FontFace instance
          const fontFace = new FontFace(
            `PreviewFont-${font.id}`,
            `url(${dataUrl})`,
            { display: 'block' }
          );

          // Load with timeout
          const loaded = await Promise.race([
            fontFace.load(),
            new Promise<never>((_, reject) => {
              const timeoutId = setTimeout(() => {
                reject(new Error('Font loading timed out'));
              }, options.timeout || 5000);

              if (options.signal) {
                options.signal.addEventListener('abort', () => {
                  clearTimeout(timeoutId);
                  reject(new Error('Font loading aborted'));
                });
              }
            })
          ]);

          // Add to document.fonts
          document.fonts.add(loaded);
          this.loadedFonts.set(font.id, loaded);

          // Wait for font to be ready
          await document.fonts.ready;
          return;
        } catch (error) {
          lastError = error as Error;
          console.warn(`Font loading attempt ${attempt + 1} failed:`, error);

          // Check if aborted
          if (options.signal?.aborted) {
            throw new Error('Font loading aborted');
          }

          // Clean up on error
          this.unloadFont(font.id);

          // Wait before retrying
          if (attempt < this.maxRetries - 1) {
            await new Promise(resolve => 
              setTimeout(resolve, this.retryDelay * Math.pow(2, attempt))
            );
          }
        }
      }

      throw lastError || new Error('Failed to load font after retries');
    })();

    // Store promise
    this.loadingPromises.set(font.id, loadPromise);

    try {
      await loadPromise;
    } finally {
      // Clean up promise
      this.loadingPromises.delete(font.id);
    }
  }

  async unloadFont(fontId: string): Promise<void> {
    // Remove style element
    const style = this.fontStyles.get(fontId);
    if (style?.parentNode) {
      style.parentNode.removeChild(style);
    }

    // Remove from document.fonts
    const fontFace = this.loadedFonts.get(fontId);
    if (fontFace) {
      try {
        document.fonts.delete(fontFace);
      } catch (error) {
        console.warn('Error removing font face:', error);
      }
    }

    // Clear from maps
    this.loadedFonts.delete(fontId);
    this.fontStyles.delete(fontId);
    this.loadingPromises.delete(fontId);

    // Force a microtask to ensure cleanup is complete
    await Promise.resolve();
  }

  async unloadAll(): Promise<void> {
    const ids = Array.from(this.loadedFonts.keys());
    await Promise.all(ids.map(id => this.unloadFont(id)));
  }
}

// Create singleton instance
export const fontLoader = new FontLoader();

// Cleanup on page unload
window.addEventListener('unload', () => {
  fontLoader.unloadAll();
});