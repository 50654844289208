import { create } from 'zustand';

interface StepProgressState {
  currentStep: number;
  completedSteps: number[];
  setCurrentStep: (step: number) => void;
  completeStep: (step: number) => void;
  isStepCompleted: (step: number) => boolean;
  canAdvance: (step: number) => boolean;
}

export const useStepProgressStore = create<StepProgressState>((set, get) => ({
  currentStep: 1,
  completedSteps: [],

  setCurrentStep: (step) => {
    if (step >= 1 && step <= 4 && get().canAdvance(step)) {
      set({ currentStep: step });
    }
  },

  completeStep: (step) => {
    set((state) => ({
      completedSteps: state.completedSteps.includes(step) 
        ? state.completedSteps 
        : [...state.completedSteps, step].sort()
    }));
  },

  isStepCompleted: (step) => {
    return get().completedSteps.includes(step);
  },

  canAdvance: (step) => {
    const state = get();
    
    // Step 1 is always accessible
    if (step === 1) return true;

    // For steps 2-4, only require step 1 to be completed
    return state.completedSteps.includes(1);
  }
}));