import React from 'react';
import type { FontFile } from '../../types/font';
import { UnicodeRangeSelector } from './UnicodeRangeSelector';
import { FontSelector } from './FontSelector';
import { CharacterPreview } from '../preview/CharacterPreview';
import { CharacterGrid } from '../preview/CharacterGrid';
import { parseUnicodeRange } from '../../utils/unicodeUtils';
import { useFontPreview } from '../../hooks/useFontPreview';
import { ViewModeToggle } from './ViewModeToggle';

interface Props {
  font: FontFile;
  fonts?: FontFile[];
  fontLoaded: boolean;
  showHeader?: boolean;
  onFontSelect?: (id: string) => void;
}

export function UnicodeCharacterMap({ 
  font, 
  fonts = [], 
  fontLoaded, 
  showHeader = true,
  onFontSelect 
}: Props) {
  const [viewMode, setViewMode] = React.useState<'preview' | 'grid'>('preview');
  const {
    currentChar,
    selectedRange,
    setSelectedRange,
    setCurrentChar,
    navigateChar
  } = useFontPreview(font);

  const range = parseUnicodeRange(selectedRange);

  return (
    <div className="flex flex-col h-full p-6">
      {/* Font Selection and Range Controls */}
      <div className="space-y-3 mb-6">
        <div className="max-w-sm mx-auto space-y-2">
          {fonts.length > 1 && onFontSelect && (
            <FontSelector
              fonts={fonts}
              selectedFont={font}
              onFontSelect={onFontSelect}
            />
          )}

          <UnicodeRangeSelector
            font={font}
            selectedRange={selectedRange}
            onRangeChange={setSelectedRange}
          />
        </div>

        {/* View Mode Toggle */}
        <div className="flex justify-center pt-2">
          <ViewModeToggle mode={viewMode} onChange={setViewMode} />
        </div>
      </div>

      {/* Character Display Area */}
      <div className="flex-1">
        {viewMode === 'preview' ? (
          <CharacterPreview
            font={font}
            fontLoaded={fontLoaded}
            currentChar={currentChar}
            range={range}
            onCharSelect={setCurrentChar}
            onNavigate={navigateChar}
          />
        ) : (
          <CharacterGrid
            font={font}
            fontLoaded={fontLoaded}
            currentChar={currentChar}
            range={range}
            onCharSelect={setCurrentChar}
          />
        )}
      </div>
    </div>
  );
}