import type { FontSettings } from '../types/font';

export const COMPRESSION_OPTIONS = [
  {
    value: 'maximum',
    name: 'Maximum',
    description: 'Best compression, slower processing'
  },
  {
    value: 'balanced',
    name: 'Balanced',
    description: 'Good compression with reasonable speed'
  },
  {
    value: 'speed',
    name: 'Speed',
    description: 'Fast processing, minimal compression'
  },
  {
    value: 'none',
    name: 'None',
    description: 'No compression, fastest processing'
  }
] as const;

export function getCompressionDescription(value: FontSettings['optimization']['compression']): string {
  return COMPRESSION_OPTIONS.find(option => option.value === value)?.description || '';
}

export function estimateCompressionRatio(compression: FontSettings['optimization']['compression']): number {
  switch (compression) {
    case 'maximum': return 0.4;
    case 'balanced': return 0.6;
    case 'speed': return 0.8;
    case 'none': return 1.0;
    default: return 0.6;
  }
}