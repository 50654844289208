import React from 'react';

interface Props {
  onBrowse: () => void;
}

export function EmptyState({ onBrowse }: Props) {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onBrowse();
  };

  return (
    <div className="text-center">
      <div 
        onClick={handleClick}
        className="w-16 h-16 mx-auto mb-8 bg-gray-900 dark:bg-white rounded-full flex items-center justify-center cursor-pointer group-hover:scale-105 transition-transform"
      >
        <svg 
          className="w-8 h-8 text-white dark:text-gray-900" 
          viewBox="0 0 24 24" 
          fill="none" 
          stroke="currentColor" 
          strokeWidth="2"
        >
          <path d="M12 5v14M5 12h14" />
        </svg>
      </div>

      <div className="space-y-2">
        <h3 className="text-base font-medium text-gray-900 dark:text-white">
          Drop font files here
        </h3>
        <p className="text-sm text-gray-500 dark:text-gray-400">
          or click anywhere to browse
        </p>
        
        <div className="flex items-center justify-center gap-2 mt-6">
          {['TTF', 'OTF', 'WOFF', 'WOFF2'].map((format) => (
            <div 
              key={format}
              className="px-2.5 py-1 bg-gray-50 dark:bg-gray-800/50 rounded-lg text-xs text-gray-500 dark:text-gray-400 font-mono border border-gray-200/50 dark:border-gray-700/50"
            >
              {format}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}