import React, { useState, useCallback, useEffect } from 'react';
import { ArrowUpDown, AlignLeft, AlignCenter, AlignRight } from 'lucide-react';
import type { FontFile } from '../../types/font';
import { FontControls } from './controls/FontControls';
import { FontSelector } from './controls/FontSelector';
import { ThemeToggle } from './controls/ThemeToggle';
import { HeroSection } from './sections/HeroSection';
import { FeaturesSection } from './sections/FeaturesSection';
import { ContentSection } from './sections/ContentSection';
import { CTASection } from './sections/CTASection';
import { BrowserFrame } from './BrowserFrame';
import type { TextAlignment } from './types';
import { generateRandomContent } from '../../utils/contentGenerator';
import { useStepProgress } from '../../hooks/useStepProgress';

interface Props {
  font: FontFile;
  fontLoaded: boolean;
  onClose: () => void;
}

export function WebsitePreview({ font, fontLoaded, onClose }: Props) {
  const [selectedFallback, setSelectedFallback] = useState(FALLBACK_FONTS[0]);
  const [swapFonts, setSwapFonts] = useState(false);
  const [primarySize, setPrimarySize] = useState(72);
  const [secondarySize, setSecondarySize] = useState(16);
  const [primaryScale, setPrimaryScale] = useState(1);
  const [secondaryScale, setSecondaryScale] = useState(1);
  const [textAlignment, setTextAlignment] = useState<TextAlignment>('center');
  const [content, setContent] = useState(generateRandomContent());
  const [previewTheme, setPreviewTheme] = useState<'light' | 'dark'>('light');
  const { completeStep } = useStepProgress();

  useEffect(() => {
    completeStep(3);
  }, [completeStep]);

  // Add ESC key handler
  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEsc);
    document.body.style.overflow = 'hidden';

    return () => {
      window.removeEventListener('keydown', handleEsc);
      document.body.style.overflow = '';
    };
  }, [onClose]);

  const handlePrimaryScaleChange = useCallback((increment: boolean) => {
    setPrimaryScale(prev => Math.max(0.5, Math.min(1.5, prev + (increment ? 0.1 : -0.1))));
  }, []);

  const handleSecondaryScaleChange = useCallback((increment: boolean) => {
    setSecondaryScale(prev => Math.max(0.5, Math.min(1.5, prev + (increment ? 0.1 : -0.1))));
  }, []);

  const getPrimarySize = (base: number, scale = 1) => {
    return Math.min(Math.round(base * scale), 72);
  };

  const getSecondarySize = (base: number, scale = 1) => {
    return Math.min(Math.round(base * scale), 20);
  };

  const getLineHeight = (fontSize: number) => {
    if (fontSize <= 20) return 1.6;
    if (fontSize <= 36) return 1.4;
    return 1.1;
  };

  const handleRefresh = useCallback(() => {
    setContent(generateRandomContent());
  }, []);

  const headingFont = swapFonts ? selectedFallback.stack : `'PreviewFont-${font.id}'`;
  const bodyFont = swapFonts ? `'PreviewFont-${font.id}'` : selectedFallback.stack;

  const sharedProps = {
    headingFont,
    bodyFont,
    primarySize: getPrimarySize(primarySize, primaryScale),
    secondarySize: getSecondarySize(secondarySize, secondaryScale),
    textAlignment,
    getLineHeight,
    content,
    theme: previewTheme
  };

  return (
    <div className="fixed inset-0 z-[100] flex items-center justify-center p-4">
      <div 
        className="fixed inset-0 bg-black/60 backdrop-blur-sm"
        onClick={onClose}
      />
      
      <div className="relative w-full max-w-[1000px] h-[min(660px,90vh)] bg-white dark:bg-dark-surface rounded-xl shadow-2xl flex flex-col overflow-hidden">
        <BrowserFrame onClose={onClose} onRefresh={handleRefresh}>
          <div className={`h-full overflow-y-auto pb-16 transition-colors ${
            previewTheme === 'dark' ? 'bg-gray-900' : 'bg-gray-50'
          }`}>
            <HeroSection {...sharedProps} />
            <FeaturesSection {...sharedProps} />
            <ContentSection {...sharedProps} />
            <CTASection {...sharedProps} />
          </div>

          {/* Controls Bar */}
          <div className="absolute bottom-0 left-0 right-0 flex items-center justify-between gap-4 px-4 py-3 border-t border-gray-200 dark:border-gray-800 bg-white dark:bg-dark-surface z-10">
            <div className="flex items-center gap-4">
              <FontControls
                size={swapFonts ? secondarySize : primarySize}
                scale={swapFonts ? secondaryScale : primaryScale}
                onSizeChange={swapFonts ? setSecondarySize : setPrimarySize}
                onScaleChange={swapFonts ? handleSecondaryScaleChange : handlePrimaryScaleChange}
                maxSize={swapFonts ? 20 : 72}
              />

              <div className="flex gap-0.5 p-0.5 bg-gray-100 dark:bg-gray-800 rounded-lg">
                {[
                  { value: 'left', icon: AlignLeft },
                  { value: 'center', icon: AlignCenter },
                  { value: 'right', icon: AlignRight }
                ].map(({ value, icon: Icon }) => (
                  <button
                    key={value}
                    onClick={() => setTextAlignment(value as TextAlignment)}
                    className={`p-1.5 rounded transition-colors ${
                      textAlignment === value
                        ? 'bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-200 shadow-sm'
                        : 'text-gray-500 dark:text-gray-400 hover:bg-white/50 dark:hover:bg-gray-600/50'
                    }`}
                  >
                    <Icon className="w-4 h-4" />
                  </button>
                ))}
              </div>
            </div>

            <div className="flex items-center gap-4">
              <ThemeToggle 
                theme={previewTheme} 
                onChange={setPreviewTheme} 
              />

              <FontSelector
                selected={selectedFallback}
                options={FALLBACK_FONTS}
                onSelect={setSelectedFallback}
              />

              <button
                onClick={() => setSwapFonts(!swapFonts)}
                className={`p-2 rounded-lg border transition-colors ${
                  swapFonts
                    ? 'bg-gray-900 dark:bg-white text-white dark:text-gray-900 border-gray-900 dark:border-white hover:bg-black dark:hover:bg-gray-100'
                    : 'bg-white dark:bg-gray-900 text-gray-600 dark:text-gray-300 border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800'
                }`}
              >
                <ArrowUpDown className="w-4 h-4" />
              </button>
            </div>
          </div>
        </BrowserFrame>
      </div>
    </div>
  );
}

const FALLBACK_FONTS = [
  { name: 'Helvetica', stack: 'Helvetica, Arial' },
  { name: 'Georgia', stack: 'Georgia, serif' },
  { name: 'Garamond', stack: 'Garamond, serif' },
  { name: 'Futura', stack: 'Futura, sans-serif' },
  { name: 'Avenir', stack: 'Avenir, sans-serif' }
];