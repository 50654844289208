import React from 'react';
import { Layers, FileDown, Wand, Rocket, Gauge, Sparkles, Cpu } from 'lucide-react';
import type { FontFile, FontSettings } from '../../../types/font';
import { COMPRESSION_OPTIONS } from '../../../tools/compression';
import { HINTING_OPTIONS } from '../../../tools/hinting';
import { SubsettingOptions } from '../../advanced/features/SubsettingOptions';

interface Props {
  font: FontFile;
  settings: FontSettings;
  onSettingsChange: (settings: Partial<FontSettings>) => void;
}

export function OptimizationContent({ font, settings, onSettingsChange }: Props) {
  const handleCompressionChange = (compression: FontSettings['optimization']['compression']) => {
    onSettingsChange({
      optimization: {
        ...settings.optimization,
        compression
      }
    });
  };

  const handleHintingChange = (hinting: FontSettings['optimization']['hinting']) => {
    onSettingsChange({
      optimization: {
        ...settings.optimization,
        hinting
      }
    });
  };

  const handleSubsettingChange = (subset: FontSettings['subset']) => {
    onSettingsChange({ subset });
  };

  const compressionIcons = {
    maximum: Rocket,
    balanced: Gauge,
    speed: Sparkles,
    none: Cpu
  };

  return (
    <div className="space-y-8">
      {/* Compression Level */}
      <div>
        <div className="flex items-center gap-2 mb-4">
          <div className="p-1.5 bg-gray-100 dark:bg-gray-800 rounded">
            <FileDown className="w-4 h-4 text-gray-900 dark:text-gray-100" />
          </div>
          <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">
            Compression Level
          </h3>
        </div>
        <div className="grid grid-cols-2 gap-3">
          {COMPRESSION_OPTIONS.map(option => {
            const Icon = compressionIcons[option.value as keyof typeof compressionIcons];
            return (
              <label
                key={option.value}
                className={`flex items-start p-4 rounded-lg border transition-colors cursor-pointer ${
                  settings.optimization.compression === option.value
                    ? 'border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-800'
                    : 'border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800/50'
                }`}
              >
                <input
                  type="radio"
                  name="compression"
                  value={option.value}
                  checked={settings.optimization.compression === option.value}
                  onChange={(e) => handleCompressionChange(e.target.value as FontSettings['optimization']['compression'])}
                  className="mt-1 text-gray-900 dark:text-gray-100 focus:ring-0 border-gray-300 dark:border-gray-600"
                />
                <div className="ml-3">
                  <div className="flex items-center gap-2">
                    <Icon className="w-4 h-4 text-gray-900 dark:text-gray-100" />
                    <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
                      {option.name}
                    </span>
                  </div>
                  <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                    {option.description}
                  </p>
                </div>
              </label>
            );
          })}
        </div>
      </div>

      {/* Subsetting */}
      <div>
        <div className="flex items-center gap-2 mb-4">
          <div className="p-1.5 bg-gray-100 dark:bg-gray-800 rounded">
            <Layers className="w-4 h-4 text-gray-900 dark:text-gray-100" />
          </div>
          <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">
            Character Subsetting
          </h3>
        </div>
        <SubsettingOptions
          value={settings.subset}
          onChange={handleSubsettingChange}
        />
      </div>

      {/* Hinting */}
      <div>
        <div className="flex items-center gap-2 mb-4">
          <div className="p-1.5 bg-gray-100 dark:bg-gray-800 rounded">
            <Wand className="w-4 h-4 text-gray-900 dark:text-gray-100" />
          </div>
          <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">
            TrueType Hinting
          </h3>
        </div>
        <div className="grid grid-cols-2 gap-3">
          {HINTING_OPTIONS.map(option => (
            <label
              key={option.id}
              className={`flex items-start p-4 rounded-lg border transition-colors cursor-pointer ${
                settings.optimization.hinting === option.id
                  ? 'border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-800'
                  : 'border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800/50'
              }`}
            >
              <input
                type="radio"
                name="hinting"
                value={option.id}
                checked={settings.optimization.hinting === option.id}
                onChange={(e) => handleHintingChange(e.target.value as FontSettings['optimization']['hinting'])}
                className="mt-1 text-gray-900 dark:text-gray-100 focus:ring-0 border-gray-300 dark:border-gray-600"
              />
              <div className="ml-3">
                <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
                  {option.name}
                </span>
                <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                  {option.description}
                </p>
              </div>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
}