import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface Props {
  currentLayout: number;
  totalLayouts: number;
  onPrevious: () => void;
  onNext: () => void;
  hideLabels?: boolean;
}

export function LayoutControls({ 
  currentLayout, 
  totalLayouts, 
  onPrevious, 
  onNext,
  hideLabels = false
}: Props) {
  return (
    <div className="flex items-center gap-4">
      <button
        onClick={onPrevious}
        className="p-2 text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 bg-gray-100 dark:bg-gray-800 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 transition-all"
        title="Previous layout"
      >
        <ChevronLeft className="w-4 h-4" />
      </button>
      <div className="text-sm font-medium text-gray-900 dark:text-gray-100 w-20 text-center hidden lg:block">
        Layout {currentLayout}
      </div>
      <button
        onClick={onNext}
        className="p-2 text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 bg-gray-100 dark:bg-gray-800 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 transition-all"
        title="Next layout"
      >
        <ChevronRight className="w-4 h-4" />
      </button>
    </div>
  );
}