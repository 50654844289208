import React from 'react';
import { Grid } from 'lucide-react';
import { useGridStore } from '../../store/gridStore';

interface Props {
  className?: string;
}

export function GridToggle({ className = '' }: Props) {
  const { showGrid, toggleGrid } = useGridStore();

  return (
    <button
      onClick={toggleGrid}
      className={`p-1.5 rounded-lg transition-colors ${
        showGrid
          ? 'bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300'
          : 'bg-gray-100 text-gray-500 dark:bg-gray-800 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700'
      } ${className}`}
      aria-label={showGrid ? 'Hide grid' : 'Show grid'}
    >
      <Grid className="w-4 h-4" />
    </button>
  );
}