import React, { useEffect, useRef } from 'react';

interface Props {
  text: string;
  onChange: (text: string) => void;
  fontSize: number;
  alignment: 'left' | 'center' | 'right';
  fontFamily: string;
  opacity: number;
  onInteraction?: () => void;
}

export function EditableText({ 
  text, 
  onChange, 
  fontSize, 
  alignment, 
  fontFamily, 
  opacity,
  onInteraction 
}: Props) {
  const editorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.textContent = text;
    }
  }, [text]);

  const handleInput = () => {
    if (editorRef.current) {
      onChange(editorRef.current.textContent || '');
      onInteraction?.();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
    onInteraction?.();
  };

  const handleFocus = () => {
    onInteraction?.();
  };

  return (
    <div
      ref={editorRef}
      contentEditable
      onInput={handleInput}
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      className="relative h-full p-6 overflow-y-auto focus:outline-none"
      style={{
        fontSize: `${fontSize}px`,
        textAlign: alignment,
        fontFamily,
        lineHeight: '1.2',
        opacity,
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word'
      }}
    />
  );
}