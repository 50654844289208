export interface UnicodeRange {
  start: number;
  end: number;
}

const DEFAULT_RANGE: UnicodeRange = {
  start: 0x0020, // Space
  end: 0x007F // Delete
};

export function parseUnicodeRange(range: string): UnicodeRange {
  if (!range) return DEFAULT_RANGE;
  
  try {
    const [start, end] = range.replace('U+', '').split('-').map(hex => parseInt(hex, 16));
    if (isNaN(start) || isNaN(end)) return DEFAULT_RANGE;
    return { start, end };
  } catch (error) {
    console.warn('Error parsing Unicode range:', error);
    return DEFAULT_RANGE;
  }
}

export function formatUnicode(charCode: number): string {
  return `U+${charCode.toString(16).padStart(4, '0').toUpperCase()}`;
}

export function getNextChar(current: number, range: UnicodeRange): number {
  return current < range.end ? current + 1 : range.start;
}

export function getPrevChar(current: number, range: UnicodeRange): number {
  return current > range.start ? current - 1 : range.end;
}

export function isCharInRange(char: number, range: UnicodeRange): boolean {
  return char >= range.start && char <= range.end;
}