import React from 'react';
import { Type, AlignLeft, AlignCenter, AlignRight } from 'lucide-react';
import { FontSizeSlider } from './FontSizeSlider';

interface Props {
  fontSize: number;
  alignment: 'left' | 'center' | 'right';
  onFontSizeChange: (size: number) => void;
  onAlignmentChange: (alignment: 'left' | 'center' | 'right') => void;
  hideLabels?: boolean;
}

export function TextControls({ 
  fontSize, 
  alignment, 
  onFontSizeChange, 
  onAlignmentChange,
  hideLabels = false
}: Props) {
  return (
    <div className="flex items-center gap-4">
      <div className="flex items-center gap-2">
        <Type className="w-4 h-4 text-gray-400 dark:text-gray-500" />
        <FontSizeSlider 
          value={fontSize} 
          onChange={onFontSizeChange}
          showLabel={!hideLabels}
        />
      </div>
      
      <div className="flex gap-0.5 p-0.5 bg-gray-100 dark:bg-gray-800 rounded-lg">
        {[
          { value: 'left', icon: AlignLeft, title: 'Align left' },
          { value: 'center', icon: AlignCenter, title: 'Align center' },
          { value: 'right', icon: AlignRight, title: 'Align right' }
        ].map(({ value, icon: Icon, title }) => (
          <button
            key={value}
            onClick={() => onAlignmentChange(value as typeof alignment)}
            className={`p-1.5 rounded transition-colors ${
              alignment === value
                ? 'bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-200 shadow-sm'
                : 'text-gray-500 dark:text-gray-400 hover:bg-white/50 dark:hover:bg-gray-600/50'
            }`}
            title={title}
          >
            <Icon className="w-4 h-4" />
          </button>
        ))}
      </div>
    </div>
  );
}