import React from 'react';

export function Footer() {
  return (
    <footer className="mt-32 border-t border-gray-200 dark:border-gray-800">
      <div className="max-w-[800px] mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row items-center justify-between gap-4">
          <div className="text-gray-500 dark:text-gray-400">
            font.zip
          </div>
          <div className="flex items-center gap-6 text-gray-400 dark:text-gray-500">
            <span className="text-xs">ALL RIGHTS RESERVED</span>
            <a 
              href="https://font.zip/eula" 
              target="_blank"
              rel="noopener noreferrer"
              className="text-xs hover:text-gray-900 dark:hover:text-gray-300 transition-colors"
            >
              EULA
            </a>
            <a 
              href="https://font.zip/privacy-policy" 
              target="_blank"
              rel="noopener noreferrer"
              className="text-xs hover:text-gray-900 dark:hover:text-gray-300 transition-colors"
            >
              PRIVACY POLICY
            </a>
            <a 
              href="https://font.zip/terms" 
              target="_blank"
              rel="noopener noreferrer"
              className="text-xs hover:text-gray-900 dark:hover:text-gray-300 transition-colors"
            >
              TERMS & CONDITIONS
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}