import React from 'react';
import { Layout1 } from './specimens/Layout1';
import { Layout2 } from './specimens/Layout2';
import { Layout3 } from './specimens/Layout3';
import { Layout4 } from './specimens/Layout4';

interface Props {
  font: string;
  layout: number;
}

export function SpecimenLayout({ font, layout }: Props) {
  switch (layout) {
    case 1:
      return <Layout1 font={font} />;
    case 2:
      return <Layout2 font={font} />;
    case 3:
      return <Layout3 font={font} />;
    case 4:
      return <Layout4 font={font} />;
    default:
      return <Layout1 font={font} />;
  }
}