import React from 'react';
import { ChevronDown, Type } from 'lucide-react';
import type { FontFile } from '../../types/font';
import { FileFormatIcon } from '../icons/FileFormatIcon';
import { getFriendlyFileType } from '../../utils/fileTypeUtils';

interface Props {
  fonts: FontFile[];
  selectedFont?: FontFile;
  onFontSelect: (id: string) => void;
}

export function FontSelector({ fonts, selectedFont, onFontSelect }: Props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between px-3 py-2 text-sm rounded-lg border border-gray-200 dark:border-dark-border bg-white dark:bg-dark-surface text-gray-900 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-gray-800/50 transition-colors"
      >
        <div className="flex items-center gap-2 min-w-0">
          <Type className="w-4 h-4 shrink-0 text-gray-400 dark:text-gray-500" />
          <span className="font-medium truncate">
            {selectedFont?.name || 'Select Font'}
          </span>
        </div>
        <ChevronDown className="w-4 h-4 shrink-0 text-gray-400 dark:text-gray-500 transition-transform duration-200" />
      </button>

      {isOpen && (
        <div className="absolute left-0 right-0 mt-1 py-1 bg-white dark:bg-dark-surface rounded-lg shadow-lg border border-gray-200 dark:border-dark-border z-10 max-h-[300px] overflow-y-auto">
          {fonts.map((font) => (
            <button
              key={font.id}
              onClick={() => {
                onFontSelect(font.id);
                setIsOpen(false);
              }}
              className={`w-full px-3 py-2 text-left transition-colors ${
                selectedFont?.id === font.id
                  ? 'bg-gray-50 dark:bg-gray-800'
                  : 'hover:bg-gray-50 dark:hover:bg-gray-800/50'
              }`}
            >
              <div className="flex items-center gap-3">
                <FileFormatIcon format={getFriendlyFileType(font)} size="sm" />
                <div className="min-w-0">
                  <div className="font-medium text-gray-900 dark:text-gray-100 truncate">
                    {font.name}
                  </div>
                  <div className="text-xs text-gray-500 dark:text-gray-400 mt-0.5">
                    {Math.round(font.size / 1024)}KB
                  </div>
                </div>
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}