import React, { useState } from 'react';
import { Globe, Type, AlignLeft, AlignCenter, AlignRight, X, Maximize2 } from 'lucide-react';
import type { FontFile } from '../../types/font';
import { FontSizeSlider } from './FontSizeSlider';
import { WebsitePreview } from './WebsitePreview';
import { EditableText } from './EditableText';
import { GridBackground } from '../common/GridBackground';
import { GridToggle } from '../common/GridToggle';
import { FullscreenPreview } from './FullscreenPreview';
import { ConfirmDialog } from '../modals/ConfirmDialog';
import { useStepProgress } from '../../hooks/useStepProgress';
import { SAMPLE_TEXTS } from '../../constants/sampleTexts';

interface Props {
  font: FontFile;
  onRemove?: (id: string) => void;
}

export function SingleFontPreview({ font, onRemove }: Props) {
  const [text, setText] = useState(SAMPLE_TEXTS[0]);
  const [fontSize, setFontSize] = useState(64);
  const [alignment, setAlignment] = useState<'left' | 'center' | 'right'>('center');
  const [selectedSample, setSelectedSample] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const [showFullscreen, setShowFullscreen] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const { completeStep } = useStepProgress();

  const handlePreviewClick = () => {
    setShowPreview(true);
    completeStep(3);
  };

  const handleRemoveClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowConfirmDialog(true);
  };

  const handleConfirmRemove = () => {
    onRemove?.(font.id);
    setShowConfirmDialog(false);
  };

  return (
    <>
      <div className="bg-white dark:bg-dark-surface rounded-lg border border-gray-200 dark:border-dark-border overflow-hidden">
        <div className="p-4 flex items-center justify-between">
          <div className="flex items-center gap-3">
            <Type className="w-4 h-4 text-gray-400 dark:text-gray-500" />
            <span className="text-sm font-medium text-gray-900 dark:text-white">
              {font.name}
            </span>
          </div>

          <div className="flex items-center gap-2">
            <button
              onClick={() => setShowFullscreen(true)}
              className="flex items-center justify-center gap-1.5 px-3 py-1.5 text-sm font-medium text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-lg transition-colors"
            >
              <Maximize2 className="w-3.5 h-3.5" />
              <span className="hidden xs:inline">Fullscreen</span>
            </button>
            <button
              onClick={handlePreviewClick}
              className="flex items-center justify-center gap-1.5 px-3 py-1.5 text-sm font-medium text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-lg transition-colors"
            >
              <Globe className="w-3.5 h-3.5" />
              <span className="hidden xs:inline">Web Preview</span>
            </button>
            {onRemove && (
              <button
                onClick={handleRemoveClick}
                className="p-1.5 text-gray-400 hover:text-gray-900 dark:text-gray-500 dark:hover:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
              >
                <X className="w-4 h-4" />
              </button>
            )}
          </div>
        </div>

        <div className="p-4 space-y-4">
          <div className="flex flex-col xs:flex-row items-start xs:items-center gap-4">
            <div className="w-full xs:w-auto flex-1">
              <select
                value={selectedSample}
                onChange={(e) => {
                  setSelectedSample(Number(e.target.value));
                  setText(SAMPLE_TEXTS[Number(e.target.value)]);
                  completeStep(3);
                }}
                className="w-full appearance-none px-3 py-2 pr-8 text-sm rounded-lg bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-gray-100 border border-gray-200 dark:border-dark-border focus:ring-0 focus:border-gray-900 dark:focus:border-white cursor-pointer"
              >
                {SAMPLE_TEXTS.map((sample, index) => (
                  <option 
                    key={index} 
                    value={index} 
                    className="bg-white dark:bg-gray-800 py-2"
                  >
                    {sample.length > 50 ? sample.slice(0, 47) + '...' : sample}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex items-center gap-4 w-full xs:w-auto">
              <div className="flex items-center gap-2">
                <Type className="w-4 h-4 text-gray-400 dark:text-gray-500" />
                <FontSizeSlider 
                  value={fontSize} 
                  onChange={(size) => {
                    setFontSize(size);
                    completeStep(3);
                  }}
                />
              </div>
              
              <div className="flex items-center gap-2">
                <div className="flex gap-0.5 p-0.5 bg-gray-100 dark:bg-gray-800 rounded-lg">
                  {[
                    { value: 'left', icon: AlignLeft },
                    { value: 'center', icon: AlignCenter },
                    { value: 'right', icon: AlignRight },
                  ].map(({ value, icon: Icon }) => (
                    <button
                      key={value}
                      onClick={() => {
                        setAlignment(value as typeof alignment);
                        completeStep(3);
                      }}
                      className={`p-1.5 rounded transition-colors ${
                        alignment === value
                          ? 'bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-200 shadow-sm'
                          : 'text-gray-500 dark:text-gray-400 hover:bg-white/50 dark:hover:bg-gray-600/50'
                      }`}
                    >
                      <Icon className="w-4 h-4" />
                    </button>
                  ))}
                </div>
                <GridToggle />
              </div>
            </div>
          </div>

          <div className="relative w-full h-[240px] min-h-[240px] cursor-text">
            <div className="absolute inset-0 border border-gray-200 dark:border-gray-800 rounded-lg bg-white dark:bg-dark-surface transition-all duration-200 ease-in-out overflow-hidden">
              <GridBackground className="text-gray-900 dark:text-gray-100" />
              <EditableText
                text={text}
                onChange={(newText) => {
                  setText(newText);
                  completeStep(3);
                }}
                fontSize={fontSize}
                alignment={alignment}
                fontFamily={`PreviewFont-${font.id}`}
                opacity={1}
                onInteraction={() => completeStep(3)}
              />
            </div>
          </div>
        </div>
      </div>

      {showPreview && (
        <WebsitePreview 
          font={font} 
          fontLoaded={true} 
          onClose={() => setShowPreview(false)} 
        />
      )}

      {showFullscreen && (
        <FullscreenPreview
          font={font}
          onClose={() => setShowFullscreen(false)}
        />
      )}

      <ConfirmDialog
        isOpen={showConfirmDialog}
        title="Remove Font"
        message={`Are you sure you want to remove "${font.name}"? This action cannot be undone.`}
        onConfirm={handleConfirmRemove}
        onCancel={() => setShowConfirmDialog(false)}
      />
    </>
  );
}