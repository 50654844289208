import React from 'react';
import { Header } from './components/Header';
import { FontGenerator } from './components/FontGenerator';
import { Footer } from './components/Footer';
import { NetworkStatus } from './components/NetworkStatus';
import { MobileWarning } from './components/MobileWarning';

export default function App() {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-dark-bg transition-colors flex flex-col">
      <MobileWarning />
      <Header />
      <div className="flex-1 w-full">
        <main className="py-8 px-4 sm:px-6 lg:px-8">
          <FontGenerator />
        </main>
      </div>
      <Footer />
      <NetworkStatus />
    </div>
  );
}