import React from 'react';
import { generateTypeSpecimen } from '../../../../utils/typeSpecimen';

interface Props {
  font: string;
}

export function Layout3({ font }: Props) {
  const specimen = React.useMemo(() => generateTypeSpecimen(), []);

  return (
    <div className="w-full max-w-[1400px] mx-auto p-12">
      {/* Header */}
      <div className="grid grid-cols-3 gap-16 mb-32">
        <div>
          <h1 
            className="text-7xl leading-[0.9] text-gray-900 dark:text-gray-100 mb-6"
            style={{ fontFamily: font }}
          >
            {specimen.displayText}
          </h1>
          <p 
            className="text-xl leading-relaxed text-gray-600 dark:text-gray-400"
            style={{ fontFamily: font }}
          >
            {specimen.subtitle}
          </p>
        </div>

        <div className="col-span-2">
          <div 
            className="text-[300px] leading-[0.8] text-gray-900 dark:text-gray-100 -mt-16"
            style={{ fontFamily: font }}
          >
            Aa
          </div>
        </div>
      </div>

      {/* Character Grid */}
      <div className="grid grid-cols-3 gap-16 mb-32">
        <div>
          <h2 className="text-sm font-medium text-gray-400 dark:text-gray-500 mb-6">
            Uppercase
          </h2>
          <div 
            className="text-4xl leading-[2] text-gray-900 dark:text-gray-100"
            style={{ fontFamily: font }}
          >
            ABCDEFGHIJKLM
            NOPQRSTUVWXYZ
          </div>
        </div>

        <div>
          <h2 className="text-sm font-medium text-gray-400 dark:text-gray-500 mb-6">
            Lowercase
          </h2>
          <div 
            className="text-4xl leading-[2] text-gray-900 dark:text-gray-100"
            style={{ fontFamily: font }}
          >
            abcdefghijklm
            nopqrstuvwxyz
          </div>
        </div>

        <div>
          <h2 className="text-sm font-medium text-gray-400 dark:text-gray-500 mb-6">
            Numbers & Symbols
          </h2>
          <div 
            className="text-4xl leading-[2] text-gray-900 dark:text-gray-100"
            style={{ fontFamily: font }}
          >
            0123456789
            !@#$%^&*()
          </div>
        </div>
      </div>

      {/* Text Sizes */}
      <div className="grid grid-cols-4 gap-16">
        {specimen.sizes.map(({ size, text }) => (
          <div key={size}>
            <span className="text-xs font-mono text-gray-400 dark:text-gray-500 block mb-3">
              {size}px
            </span>
            <p 
              className="text-gray-900 dark:text-gray-100 truncate"
              style={{ 
                fontFamily: font, 
                fontSize: `${size}px`,
                lineHeight: '1.2'
              }}
            >
              {text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}