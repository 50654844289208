import React from 'react';
import { X } from 'lucide-react';
import type { FontFile } from '../../types/font';
import { FileFormatIcon } from '../icons/FileFormatIcon';
import { getFriendlyFileType } from '../../utils/fileTypeUtils';

interface Props {
  file: FontFile;
  isSelected: boolean;
  onSelect: (id: string) => void;
  onRemove: (id: string) => void;
}

export function FileListItem({ file, isSelected, onSelect, onRemove }: Props) {
  const format = getFriendlyFileType(file);

  return (
    <div
      onClick={() => onSelect(file.id)}
      className={`group relative w-full flex items-center justify-between px-3 py-2 rounded-lg border transition-all cursor-pointer ${
        isSelected
          ? 'bg-gray-100 dark:bg-gray-800 border-gray-300 dark:border-gray-600 shadow-sm'
          : 'bg-white dark:bg-dark-surface border-gray-200 dark:border-dark-border hover:bg-gray-50 dark:hover:bg-gray-800/50'
      }`}
    >
      <div className="flex items-center gap-3 min-w-0">
        <FileFormatIcon format={format} size="md" />
        
        <div className="min-w-0">
          <div className="flex items-center gap-2">
            <span className="text-sm font-medium text-gray-900 dark:text-gray-100 truncate">
              {file.name}
            </span>
            <span className="text-xs text-gray-500 dark:text-gray-400">
              {Math.round(file.size / 1024)}KB
            </span>
          </div>
        </div>
      </div>

      <button
        onClick={(e) => {
          e.stopPropagation();
          onRemove(file.id);
        }}
        className={`p-1.5 rounded-md transition-all ${
          isSelected
            ? 'text-gray-400 hover:text-gray-900 dark:text-gray-500 dark:hover:text-gray-100 hover:bg-white dark:hover:bg-gray-900'
            : 'text-gray-400 hover:text-gray-900 dark:text-gray-500 dark:hover:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-800 opacity-0 group-hover:opacity-100'
        }`}
        aria-label="Remove font"
      >
        <X className="w-4 h-4" />
      </button>
    </div>
  );
}