import React, { useState, useRef, useEffect } from 'react';

interface Props {
  content: React.ReactNode;
  children: React.ReactNode;
  position?: 'top' | 'bottom' | 'left' | 'right';
  delay?: number;
  className?: string;
}

export function Tooltip({ content, children, position = 'top', delay = 0, className = '' }: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [isPositioned, setIsPositioned] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const tooltipRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const positioningRef = useRef<number>();

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (positioningRef.current) {
        cancelAnimationFrame(positioningRef.current);
      }
    };
  }, []);

  const updatePosition = () => {
    if (!tooltipRef.current || !containerRef.current) return;

    const container = containerRef.current.getBoundingClientRect();
    const tooltip = tooltipRef.current.getBoundingClientRect();
    const viewport = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    let x = 0;
    let y = 0;

    // Calculate position based on placement, centering from the middle of the button
    switch (position) {
      case 'top':
        x = container.left + (container.width - tooltip.width) / 2;
        y = container.top - tooltip.height - 8;
        break;
      case 'bottom':
        x = container.left + (container.width - tooltip.width) / 2;
        y = container.bottom + 8;
        break;
      case 'left':
        x = container.left - tooltip.width - 8;
        y = container.top + (container.height - tooltip.height) / 2;
        break;
      case 'right':
        x = container.right + 8;
        y = container.top + (container.height - tooltip.height) / 2;
        break;
    }

    // Keep tooltip within viewport bounds
    x = Math.max(8, Math.min(x, viewport.width - tooltip.width - 8));
    y = Math.max(8, Math.min(y, viewport.height - tooltip.height - 8));

    // If tooltip would be outside viewport on top, show below instead
    if (position === 'top' && y < 8) {
      y = container.bottom + 8;
    }

    setTooltipPosition({ x, y });
    setIsPositioned(true);
  };

  const showTooltip = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setIsVisible(true);
      setIsPositioned(false);
      // Use double RAF to ensure tooltip is rendered before measuring
      requestAnimationFrame(() => {
        positioningRef.current = requestAnimationFrame(() => {
          updatePosition();
        });
      });
    }, delay);
  };

  const hideTooltip = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (positioningRef.current) {
      cancelAnimationFrame(positioningRef.current);
    }
    setIsVisible(false);
    setIsPositioned(false);
  };

  return (
    <>
      <div
        ref={containerRef}
        className={`inline-flex ${className}`}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        onFocus={showTooltip}
        onBlur={hideTooltip}
      >
        {children}
      </div>

      {isVisible && (
        <div
          ref={tooltipRef}
          className="fixed z-[9999] pointer-events-none"
          style={{
            left: tooltipPosition.x,
            top: tooltipPosition.y,
            opacity: isPositioned ? 1 : 0,
            transform: `scale(${isPositioned ? 1 : 0.95})`,
            transformOrigin: 'center',
            transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1), transform 150ms cubic-bezier(0.4, 0, 0.2, 1)',
            visibility: isPositioned ? 'visible' : 'hidden'
          }}
          role="tooltip"
          aria-hidden={!isVisible}
        >
          <div className="px-2 py-1 text-xs font-medium text-white dark:text-gray-900 bg-gray-900 dark:bg-white rounded shadow-lg whitespace-pre-wrap max-w-xs">
            {content}
          </div>
        </div>
      )}
    </>
  );
}