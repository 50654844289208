import React from 'react';
import { Smartphone } from 'lucide-react';

export function MobileWarning() {
  return (
    <div className="fixed inset-0 z-[200] bg-white dark:bg-dark-bg sm:hidden flex items-center justify-center p-6">
      <div className="max-w-sm w-full text-center space-y-4">
        <div className="flex justify-center">
          <div className="w-16 h-16 bg-gray-100 dark:bg-gray-800 rounded-full flex items-center justify-center">
            <Smartphone className="w-8 h-8 text-gray-500 dark:text-gray-400" />
          </div>
        </div>
        <div className="space-y-2">
          <h1 className="text-lg font-medium text-gray-900 dark:text-white">
            Mobile Version Coming Soon
          </h1>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            font.zip is currently optimized for desktop use. Please visit us on a larger screen for the best experience.
          </p>
        </div>
      </div>
    </div>
  );
}