import React from 'react';
import { ChevronDown, Languages } from 'lucide-react';
import type { FontFile } from '../../types/font';
import { UNICODE_RANGES } from '../../utils/unicodeRanges';

interface Props {
  font: FontFile;
  selectedRange: string;
  onRangeChange: (range: string) => void;
}

export function UnicodeRangeSelector({ font, selectedRange, onRangeChange }: Props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  // Get supported ranges from font metadata
  const supportedRanges = font.metadata?.supportedRanges || ['U+0020-007F'];

  // Filter ranges to only show supported ones and mark unavailable ones
  const availableRanges = React.useMemo(() => {
    return UNICODE_RANGES.map(range => ({
      ...range,
      available: supportedRanges.includes(range.range)
    }));
  }, [supportedRanges]);

  const selectedRangeData = availableRanges.find(r => r.range === selectedRange);

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Ensure selected range is supported on font change
  React.useEffect(() => {
    if (!supportedRanges.includes(selectedRange)) {
      onRangeChange(supportedRanges[0]);
    }
  }, [font.id, supportedRanges, selectedRange, onRangeChange]);

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between px-3 py-2 text-sm rounded-lg border border-gray-200 dark:border-dark-border bg-white dark:bg-dark-surface text-gray-900 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-gray-800/50 transition-colors"
      >
        <div className="flex items-center gap-2">
          <Languages className="w-4 h-4 text-gray-400 dark:text-gray-500" />
          <span className="font-medium">
            {selectedRangeData?.name || 'Basic Latin'}
          </span>
        </div>
        <ChevronDown className={`w-4 h-4 text-gray-400 dark:text-gray-500 transition-transform duration-200 ${
          isOpen ? 'rotate-180' : ''
        }`} />
      </button>

      {isOpen && (
        <div className="absolute left-0 right-0 mt-1 py-1 bg-white dark:bg-dark-surface rounded-lg shadow-lg border border-gray-200 dark:border-dark-border z-10 max-h-[300px] overflow-y-auto">
          {availableRanges.map((range) => (
            <button
              key={range.id}
              onClick={() => {
                if (range.available) {
                  onRangeChange(range.range);
                  setIsOpen(false);
                }
              }}
              disabled={!range.available}
              className={`w-full px-4 py-3 text-left transition-colors ${
                range.available
                  ? selectedRange === range.range
                    ? 'bg-gray-50 dark:bg-gray-800'
                    : 'hover:bg-gray-50 dark:hover:bg-gray-800/50'
                  : 'opacity-50 cursor-not-allowed'
              }`}
            >
              <div className="flex items-center justify-between">
                <div className="min-w-0">
                  <div className={`font-medium text-gray-900 dark:text-gray-100 ${
                    !range.available && 'line-through'
                  }`}>
                    {range.name}
                  </div>
                  <div className="flex items-center gap-2 mt-1">
                    <code className="text-xs bg-gray-100 dark:bg-gray-800 px-1.5 py-0.5 rounded text-gray-600 dark:text-gray-400 font-mono">
                      {range.range}
                    </code>
                    {!range.available && (
                      <span className="text-xs text-gray-500 dark:text-gray-400">
                        Not available in font
                      </span>
                    )}
                  </div>
                </div>
                {selectedRange === range.range && range.available && (
                  <svg className="w-4 h-4 text-gray-900 dark:text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                )}
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}