import { useState, useCallback } from 'react';
import JSZip from 'jszip';
import type { FontFile, FontSettings } from '../types/font';
import { getFontMetadata, sanitizeFontName, detectFontType, createFontPreviewUrl } from '../utils/fontUtils';
import { fontLoader } from '../utils/fontLoader';
import { useSettingsStore } from '../store/settingsStore';
import { DEFAULT_SETTINGS } from '../constants/defaultSettings';
import { generateTypeSpecimen } from '../utils/typeSpecimen';
import { downloadZipFile } from '../utils/downloadUtils';

export function useFontProcessor() {
  const [files, setFiles] = useState<FontFile[]>([]);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showDownloadNotification, setShowDownloadNotification] = useState(false);
  const { updateSettings, getSettings, clearSettings } = useSettingsStore();

  const addFonts = useCallback(async (newFiles: File[]) => {
    setError(null);
    
    try {
      const fontFiles = await Promise.all(
        newFiles.map(async (file) => {
          try {
            const arrayBuffer = await file.arrayBuffer();
            const type = detectFontType(file);
            const preview = createFontPreviewUrl(arrayBuffer, type);
            const id = crypto.randomUUID();
            const metadata = await getFontMetadata(file);
            const cssFilename = sanitizeFontName(metadata.fullName || file.name);

            const fontFile: FontFile = {
              id,
              name: metadata.fullName || file.name,
              size: file.size,
              type,
              preview,
              originalFile: file,
              metadata,
              settings: {
                ...DEFAULT_SETTINGS,
                cssFilename,
                fontFamily: metadata.fullName || file.name
              }
            };

            await fontLoader.loadFont(fontFile);
            return fontFile;
          } catch (error) {
            console.error('Error processing font:', file.name, error);
            throw new Error(`Failed to process font "${file.name}"`);
          }
        })
      );

      setFiles(prev => [...prev, ...fontFiles]);
    } catch (error) {
      console.error('Error adding fonts:', error);
      setError('Failed to process one or more fonts. Please try again.');
    }
  }, []);

  const removeFontById = useCallback((id: string) => {
    setFiles(prev => {
      const fileToRemove = prev.find(file => file.id === id);
      if (fileToRemove) {
        if (fileToRemove.preview) {
          URL.revokeObjectURL(fileToRemove.preview);
        }
        fontLoader.unloadFont(fileToRemove.id);
        clearSettings(fileToRemove.id);
      }
      return prev.filter(file => file.id !== id);
    });
  }, [clearSettings]);

  const updateFontSettings = useCallback((id: string, settings: Partial<FontSettings>) => {
    updateSettings(id, settings);
  }, [updateSettings]);

  const generateReadme = (fontName: string): string => {
    return `Web Font Package - ${fontName}

This package contains web font files and necessary CSS for embedding the font in your web projects.

Files included:
- Font files in multiple formats (WOFF2, WOFF, etc.)
- CSS file with @font-face declarations
- Type specimen preview (type-specimen.html)

Usage:
1. Copy the font files and CSS to your project
2. Link the CSS file in your HTML:
   <link rel="stylesheet" href="${fontName}.css">
3. Use the font in your CSS:
   font-family: '${fontName}';

For more information about web fonts, visit:
https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face

Generated with font.zip
`;
  };

  const generateTypeSpecimenHtml = (fontName: string, fontFamily: string, specimen = generateTypeSpecimen()): string => {
    return `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Type Specimen - ${fontFamily}</title>
    <link rel="stylesheet" href="${fontName}.css">
    <style>
        :root {
            color-scheme: light dark;
        }
        * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }
        body {
            font-family: system-ui, -apple-system, sans-serif;
            line-height: 1.5;
        }
        .section {
            padding: 96px 32px;
            min-height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .section:nth-child(odd) {
            background: #fff;
            color: #000;
        }
        .section:nth-child(even) {
            background: #000;
            color: #fff;
        }
        .container {
            width: 100%;
            max-width: 1400px;
            margin: 0 auto;
        }
        .hero {
            text-align: center;
        }
        .hero-title {
            font-family: '${fontFamily}';
            font-size: clamp(64px, 15vw, 200px);
            line-height: 1;
            margin-bottom: 32px;
        }
        .hero-subtitle {
            font-family: '${fontFamily}';
            font-size: clamp(24px, 5vw, 48px);
            line-height: 1.2;
            max-width: 800px;
            margin: 0 auto;
            opacity: 0.7;
        }
        .character-set {
            font-family: '${fontFamily}';
            font-size: clamp(24px, 5vw, 48px);
            line-height: 1.5;
            word-break: break-all;
            text-align: center;
        }
        .size-scale {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 64px;
        }
        .size-item {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
        .size-label {
            font-family: ui-monospace, monospace;
            font-size: 14px;
            opacity: 0.5;
        }
        .size-text {
            font-family: '${fontFamily}';
            line-height: 1.2;
        }
        .paragraphs {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 64px;
        }
        .paragraph {
            font-family: '${fontFamily}';
            font-size: clamp(16px, 2vw, 24px);
            line-height: 1.6;
        }
        .section-title {
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            opacity: 0.5;
            margin-bottom: 48px;
            text-align: center;
        }
        @media (prefers-color-scheme: dark) {
            .section:nth-child(odd) {
                background: #000;
                color: #fff;
            }
            .section:nth-child(even) {
                background: #fff;
                color: #000;
            }
        }
    </style>
</head>
<body>
    <section class="section">
        <div class="container hero">
            <h1 class="hero-title">${specimen.displayText}</h1>
            <p class="hero-subtitle">${specimen.subtitle}</p>
        </div>
    </section>

    <section class="section">
        <div class="container">
            <h2 class="section-title">Character Set</h2>
            <div class="character-set">${specimen.characterSet}</div>
        </div>
    </section>

    <section class="section">
        <div class="container">
            <h2 class="section-title">Size Scale</h2>
            <div class="size-scale">
                ${specimen.sizes.map(({ size, text }) => `
                <div class="size-item">
                    <div class="size-label">${size}px</div>
                    <div class="size-text" style="font-size: ${size}px">${text}</div>
                </div>
                `).join('')}
            </div>
        </div>
    </section>

    <section class="section">
        <div class="container">
            <h2 class="section-title">Sample Text</h2>
            <div class="paragraphs">
                ${specimen.paragraphs.map(text => `
                <div class="paragraph">${text}</div>
                `).join('')}
            </div>
        </div>
    </section>
</body>
</html>`;
  };

  const processFont = useCallback(async (type: 'individual' | 'all') => {
    if (files.length === 0) return;

    setProcessing(true);
    setError(null);

    try {
      const zip = new JSZip();
      
      for (const font of files) {
        const settings = getSettings(font.id);
        const fontName = settings.cssFilename || sanitizeFontName(font.metadata?.fullName || font.name);
        const folderName = font.metadata?.fullName || font.name;
        const fontFamily = settings.fontFamily || font.name;
        
        const cssContent = `@font-face {
  font-family: '${fontFamily}';
  src: ${settings.formats.map(format => {
    const ext = format.toLowerCase();
    return `url('${fontName}.${ext}') format('${ext === 'ttf' ? 'truetype' : ext}')`;
  }).join(',\n       ')};
  font-weight: normal;
  font-style: normal;
  font-display: ${settings.fontDisplay || 'swap'};${settings.opentype?.features?.length ? `
  font-feature-settings: ${settings.opentype.features.map(f => `"${f}" 1`).join(', ')};` : ''}${settings.metrics?.mode === 'custom' ? `
  ascent-override: ${Math.abs(settings.metrics.ascent || 0)}%;
  descent-override: ${Math.abs(settings.metrics.descent || 0)}%;
  line-gap-override: ${Math.abs(settings.metrics.lineGap || 0)}%;` : ''}
}`;

        if (type === 'individual') {
          const individualZip = new JSZip();
          individualZip.file(`${fontName}.css`, cssContent);
          individualZip.file('README.txt', generateReadme(fontFamily));
          individualZip.file('type-specimen.html', generateTypeSpecimenHtml(fontName, fontFamily));
          
          for (const format of settings.formats) {
            const ext = format.toLowerCase();
            individualZip.file(`${fontName}.${ext}`, await font.originalFile.arrayBuffer());
          }

          await downloadZipFile(
            individualZip, 
            `${fontName}-webfont.zip`,
            () => setShowDownloadNotification(true)
          );
        } else {
          const folderPath = `${folderName}`;
          zip.file(`${folderPath}/${fontName}.css`, cssContent);
          zip.file(`${folderPath}/README.txt`, generateReadme(fontFamily));
          zip.file(`${folderPath}/type-specimen.html`, generateTypeSpecimenHtml(fontName, fontFamily));
          
          for (const format of settings.formats) {
            const ext = format.toLowerCase();
            zip.file(`${folderPath}/${fontName}.${ext}`, await font.originalFile.arrayBuffer());
          }
        }
      }

      if (type === 'all') {
        await downloadZipFile(
          zip, 
          'Webfonts.zip',
          () => setShowDownloadNotification(true)
        );
      }
    } catch (error) {
      console.error('Error generating webfonts:', error);
      setError('Failed to generate web fonts. Please try again.');
    } finally {
      setProcessing(false);
    }
  }, [files, getSettings]);

  return { 
    files, 
    processing,
    error,
    showDownloadNotification,
    setShowDownloadNotification,
    addFonts, 
    removeFontById, 
    updateFontSettings,
    processFont 
  };
}