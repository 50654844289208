import React, { useState } from 'react';
import type { FontSettings } from '../../../types/font';
import { CustomSubsettingOptions } from './CustomSubsettingOptions';

interface Props {
  value: FontSettings['subset'];
  onChange: (value: FontSettings['subset']) => void;
}

export function SubsettingOptions({ value, onChange }: Props) {
  const [customSettings, setCustomSettings] = useState({
    encoding: 'macroman',
    characterTypes: [] as string[],
    languages: [] as string[],
    unicodeTables: [] as string[]
  });

  return (
    <div className="space-y-4">
      <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">
        Subsetting
      </h3>

      <div className="space-y-3">
        <label className={`flex items-start p-3 rounded-lg border transition-colors cursor-pointer ${
          value === 'basic'
            ? 'border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-800'
            : 'border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800/50'
        }`}>
          <input
            type="radio"
            name="subsetting"
            value="basic"
            checked={value === 'basic'}
            onChange={(e) => onChange(e.target.value as FontSettings['subset'])}
            className="mt-1 text-gray-900 dark:text-gray-100 focus:ring-0 border-gray-300 dark:border-gray-600"
          />
          <div className="ml-2">
            <div className="text-sm text-gray-900 dark:text-gray-100">
              Basic Subsetting
            </div>
            <p className="text-xs text-gray-500 dark:text-gray-400 mt-0.5">
              Western languages
            </p>
          </div>
        </label>

        <div>
          <label className={`flex items-start p-3 rounded-lg border transition-colors cursor-pointer ${
            value === 'custom'
              ? 'border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-800'
              : 'border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800/50'
          }`}>
            <input
              type="radio"
              name="subsetting"
              value="custom"
              checked={value === 'custom'}
              onChange={(e) => onChange(e.target.value as FontSettings['subset'])}
              className="mt-1 text-gray-900 dark:text-gray-100 focus:ring-0 border-gray-300 dark:border-gray-600"
            />
            <div className="ml-2">
              <div className="text-sm text-gray-900 dark:text-gray-100">
                Custom Subsetting
              </div>
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-0.5">
                Custom language support
              </p>
            </div>
          </label>

          {value === 'custom' && (
            <div className="mt-4 ml-8">
              <CustomSubsettingOptions
                selectedEncoding={customSettings.encoding}
                selectedCharacterTypes={customSettings.characterTypes}
                selectedLanguages={customSettings.languages}
                selectedUnicodeTables={customSettings.unicodeTables}
                onEncodingChange={(encoding) => setCustomSettings(prev => ({ ...prev, encoding }))}
                onCharacterTypesChange={(types) => setCustomSettings(prev => ({ ...prev, characterTypes: types }))}
                onLanguagesChange={(languages) => setCustomSettings(prev => ({ ...prev, languages }))}
                onUnicodeTablesChange={(tables) => setCustomSettings(prev => ({ ...prev, unicodeTables: tables }))}
              />
            </div>
          )}
        </div>

        <label className={`flex items-start p-3 rounded-lg border transition-colors cursor-pointer ${
          value === 'none'
            ? 'border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-800'
            : 'border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800/50'
        }`}>
          <input
            type="radio"
            name="subsetting"
            value="none"
            checked={value === 'none'}
            onChange={(e) => onChange(e.target.value as FontSettings['subset'])}
            className="mt-1 text-gray-900 dark:text-gray-100 focus:ring-0 border-gray-300 dark:border-gray-600"
          />
          <div className="ml-2">
            <div className="text-sm text-gray-900 dark:text-gray-100">
              No Subsetting
            </div>
            <p className="text-xs text-gray-500 dark:text-gray-400 mt-0.5">
              Include all characters
            </p>
          </div>
        </label>
      </div>
    </div>
  );
}