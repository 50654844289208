import type { FontFile } from '../types/font';

const TYPE_MAP: Record<string, string> = {
  'font/ttf': 'TTF',
  'font/otf': 'OTF',
  'font/woff': 'WOFF',
  'font/woff2': 'WOFF2',
  'application/x-font-ttf': 'TTF',
  'application/x-font-otf': 'OTF',
  'application/font-woff': 'WOFF',
  'application/font-woff2': 'WOFF2'
};

export function getFriendlyFileType(file: File | FontFile): string {
  // If it's a File object, check the name first
  if (file instanceof File) {
    const extension = file.name.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'ttf': return 'TTF';
      case 'otf': return 'OTF';
      case 'woff': return 'WOFF';
      case 'woff2': return 'WOFF2';
    }
  }

  // Check MIME type
  const type = ('type' in file ? file.type : file instanceof File ? file.type : '').toLowerCase();
  return TYPE_MAP[type] || 'TTF';
}

export function getFileExtension(filename: string): string {
  const ext = filename.split('.').pop()?.toLowerCase();
  switch (ext) {
    case 'ttf': return 'TTF';
    case 'otf': return 'OTF';
    case 'woff': return 'WOFF';
    case 'woff2': return 'WOFF2';
    default: return 'TTF';
  }
}

export function getMimeType(extension: string): string {
  switch (extension.toLowerCase()) {
    case 'ttf': return 'font/ttf';
    case 'otf': return 'font/otf';
    case 'woff': return 'font/woff';
    case 'woff2': return 'font/woff2';
    default: return 'font/ttf';
  }
}