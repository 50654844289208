export const OPENTYPE_FEATURES = [
  // Standard Features
  { id: 'liga', name: 'Standard Ligatures', description: 'Common letter combinations like "fi" and "fl"' },
  { id: 'kern', name: 'Kerning', description: 'Adjusts spacing between specific letter pairs' },
  { id: 'calt', name: 'Contextual Alternates', description: 'Context-dependent character variants' },
  { id: 'dlig', name: 'Discretionary Ligatures', description: 'Optional decorative letter combinations' },
  
  // Number Variants
  { id: 'lnum', name: 'Lining Numerals', description: 'Numbers with uniform heights' },
  { id: 'onum', name: 'Oldstyle Numerals', description: 'Numbers with varying heights' },
  { id: 'pnum', name: 'Proportional Numerals', description: 'Variable-width numbers' },
  { id: 'tnum', name: 'Tabular Numerals', description: 'Fixed-width numbers' },
  { id: 'zero', name: 'Slashed Zero', description: 'Zero with a diagonal line through it' },
  
  // Case Modifications
  { id: 'smcp', name: 'Small Capitals', description: 'Lowercase letters as small capitals' },
  { id: 'c2sc', name: 'Capitals to Small Caps', description: 'Uppercase letters as small capitals' },
  { id: 'case', name: 'Case-Sensitive Forms', description: 'Adjusted forms for all-caps settings' },
  { id: 'cpsp', name: 'Capital Spacing', description: 'Adjusted spacing for all-caps text' },
  
  // Stylistic Sets
  { id: 'ss01', name: 'Stylistic Set 1', description: 'Alternative character designs' },
  { id: 'ss02', name: 'Stylistic Set 2', description: 'Additional character variants' },
  { id: 'ss03', name: 'Stylistic Set 3', description: 'Third set of alternates' },
  { id: 'ss04', name: 'Stylistic Set 4', description: 'Fourth set of alternates' },
  
  // Fractions and Scientific
  { id: 'frac', name: 'Fractions', description: 'Diagonal fractions' },
  { id: 'afrc', name: 'Alternative Fractions', description: 'Stacked fractions' },
  { id: 'sups', name: 'Superscript', description: 'Superscript numbers and letters' },
  { id: 'subs', name: 'Subscript', description: 'Subscript numbers and letters' },
  { id: 'numr', name: 'Numerators', description: 'Numbers above the baseline' },
  { id: 'dnom', name: 'Denominators', description: 'Numbers below the baseline' },
  
  // Typographic Extras
  { id: 'swsh', name: 'Swashes', description: 'Decorative letter variants' },
  { id: 'salt', name: 'Stylistic Alternates', description: 'Alternative character designs' },
  { id: 'nalt', name: 'Alternate Annotation', description: 'Alternate forms of parentheses etc.' },
  { id: 'hist', name: 'Historical Forms', description: 'Historical character variants' },
  { id: 'titl', name: 'Titling', description: 'Forms designed for large sizes' },
  
  // Language-Specific
  { id: 'locl', name: 'Localized Forms', description: 'Language-specific glyph variants' },
  { id: 'ccmp', name: 'Glyph Composition', description: 'Character composition/decomposition' },
  { id: 'mark', name: 'Mark Positioning', description: 'Mark-to-base positioning' },
  { id: 'mkmk', name: 'Mark-to-Mark', description: 'Mark-to-mark positioning' }
] as const;

export const FEATURE_CATEGORIES = [
  { 
    id: 'standard',
    name: 'Standard Features',
    features: ['liga', 'kern', 'calt', 'dlig']
  },
  {
    id: 'numbers',
    name: 'Number Variants',
    features: ['lnum', 'onum', 'pnum', 'tnum', 'zero']
  },
  {
    id: 'case',
    name: 'Case Modifications',
    features: ['smcp', 'c2sc', 'case', 'cpsp']
  },
  {
    id: 'stylistic',
    name: 'Stylistic Sets',
    features: ['ss01', 'ss02', 'ss03', 'ss04']
  },
  {
    id: 'fractions',
    name: 'Fractions & Scientific',
    features: ['frac', 'afrc', 'sups', 'subs', 'numr', 'dnom']
  },
  {
    id: 'typography',
    name: 'Typographic Extras',
    features: ['swsh', 'salt', 'nalt', 'hist', 'titl']
  },
  {
    id: 'language',
    name: 'Language-Specific',
    features: ['locl', 'ccmp', 'mark', 'mkmk']
  }
] as const;