import React, { useState, useEffect } from 'react';
import { TextCursor, Layout, X } from 'lucide-react';
import type { FontFile } from '../../types/font';
import { Editor } from './Editor';
import { TypeSpecimen } from './TypeSpecimen';
import { SAMPLE_TEXTS } from '../../constants/sampleTexts';
import { ViewModeToggle } from './ViewModeToggle';
import { TextControls } from './TextControls';
import { LayoutControls } from './LayoutControls';
import { SampleTextSelector } from './SampleTextSelector';
import { GridToggle } from '../common/GridToggle';
import { ThemeToggle } from './controls/ThemeToggle';
import { fontLoader } from '../../utils/fontLoader';
import { useThemeStore } from '../../store/themeStore';

interface Props {
  font: FontFile;
  onClose: () => void;
}

export function FullscreenPreview({ font, onClose }: Props) {
  const [text, setText] = useState(SAMPLE_TEXTS[0]);
  const [fontSize, setFontSize] = useState(96);
  const [alignment, setAlignment] = useState<'left' | 'center' | 'right'>('center');
  const [selectedSample, setSelectedSample] = useState(0);
  const [viewMode, setViewMode] = useState<'edit' | 'specimen'>('edit');
  const [currentLayout, setCurrentLayout] = useState(1);
  const [showSamples, setShowSamples] = useState(false);
  const [showGrid, setShowGrid] = useState(true);
  const [fontLoaded, setFontLoaded] = useState(false);
  const { theme, setTheme } = useThemeStore();

  useEffect(() => {
    if (viewMode === 'specimen') {
      setShowGrid(false);
    } else {
      setShowGrid(true);
    }
  }, [viewMode]);

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEsc);
    document.body.style.overflow = 'hidden';

    return () => {
      window.removeEventListener('keydown', handleEsc);
      document.body.style.overflow = '';
    };
  }, [onClose]);

  useEffect(() => {
    let mounted = true;
    const controller = new AbortController();

    const loadFont = async () => {
      try {
        await fontLoader.loadFont(font, { signal: controller.signal });
        if (mounted) {
          setFontLoaded(true);
        }
      } catch (error) {
        if (!controller.signal.aborted) {
          console.error('Error loading font:', error);
        }
      }
    };

    setFontLoaded(false);
    loadFont();

    return () => {
      mounted = false;
      controller.abort();
    };
  }, [font.id]);

  const handleSampleSelect = (index: number) => {
    setSelectedSample(index);
    setText(SAMPLE_TEXTS[index]);
    setShowSamples(false);
  };

  const nextLayout = () => {
    setCurrentLayout(prev => prev === 4 ? 1 : prev + 1);
  };

  const prevLayout = () => {
    setCurrentLayout(prev => prev === 1 ? 4 : prev - 1);
  };

  if (!fontLoaded) {
    return (
      <div className="fixed inset-0 z-[200] flex items-center justify-center bg-black/60 backdrop-blur-sm">
        <div className="text-center">
          <div className="w-8 h-8 border-2 border-gray-300 border-t-gray-900 rounded-full animate-spin mx-auto mb-4" />
          <p className="text-sm text-white">Loading font...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 z-[200] flex items-center justify-center p-6">
      {/* Dark blurred backdrop */}
      <div className="fixed inset-0 bg-black/60 backdrop-blur-sm" onClick={onClose} />

      {/* Preview container */}
      <div className="relative w-full max-w-[1400px] h-full max-h-[calc(100vh-3rem)] bg-white dark:bg-dark-surface rounded-xl shadow-2xl flex flex-col overflow-hidden">
        {/* Header */}
        <div className="shrink-0 p-4 bg-gray-50 dark:bg-gray-800/50 border-b border-gray-200 dark:border-dark-border flex items-center justify-between">
          <div className="flex items-center gap-2">
            {viewMode === 'edit' ? (
              <TextCursor className="w-4 h-4 text-gray-500 dark:text-gray-400" />
            ) : (
              <Layout className="w-4 h-4 text-gray-500 dark:text-gray-400" />
            )}
            <div>
              <h2 className="text-sm font-medium text-gray-900 dark:text-gray-100">
                {viewMode === 'edit' ? 'Text Editor' : 'Type Specimen'}
              </h2>
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-0.5 max-w-[200px] truncate">
                {font.name}
              </p>
            </div>
          </div>
          <button
            onClick={onClose}
            className="p-1.5 text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
          >
            <X className="w-4 h-4" />
          </button>
        </div>

        {/* Main Content */}
        <div className="flex-1 overflow-hidden">
          {viewMode === 'edit' ? (
            <Editor
              font={`PreviewFont-${font.id}`}
              text={text}
              fontSize={fontSize}
              alignment={alignment}
              onChange={setText}
              hideGrid={!showGrid}
              className="h-full"
            />
          ) : (
            <TypeSpecimen 
              font={`PreviewFont-${font.id}`} 
              layout={currentLayout}
              hideGrid
              className="h-full"
            />
          )}
        </div>

        {/* Bottom Controls */}
        <div className="shrink-0 p-4 sm:p-6 bg-gray-50 dark:bg-gray-800/50 border-t border-gray-200 dark:border-dark-border">
          <div className="flex flex-wrap items-center justify-between gap-4">
            <div className="flex flex-wrap items-center gap-4">
              <ViewModeToggle mode={viewMode} onModeChange={setViewMode} />
              
              {viewMode === 'edit' && (
                <div className="flex flex-wrap items-center gap-4">
                  <div className="hidden xl:block">
                    <SampleTextSelector
                      selectedIndex={selectedSample}
                      onSelect={handleSampleSelect}
                      isOpen={showSamples}
                      onToggle={() => setShowSamples(!showSamples)}
                    />
                  </div>
                  <TextControls
                    fontSize={fontSize}
                    alignment={alignment}
                    onFontSizeChange={setFontSize}
                    onAlignmentChange={setAlignment}
                    hideLabels
                  />
                  <GridToggle />
                </div>
              )}
            </div>

            <div className="flex items-center gap-4">
              {viewMode === 'specimen' && (
                <LayoutControls
                  currentLayout={currentLayout}
                  totalLayouts={4}
                  onPrevious={prevLayout}
                  onNext={nextLayout}
                  hideLabels
                />
              )}
              <ThemeToggle 
                theme={theme} 
                onChange={setTheme}
                hideLabel
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}