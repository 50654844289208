import React from 'react';
import { Monitor } from 'lucide-react';
import type { FontSettings } from '../../../types/font';

interface Props {
  value: FontSettings['rendering'];
  onChange: (value: Partial<FontSettings['rendering']>) => void;
}

export function RenderingSection({ value, onChange }: Props) {
  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2">
        <Monitor className="w-4 h-4 text-gray-400 dark:text-gray-500" />
        <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">
          Rendering Options
        </h3>
      </div>

      <div className="space-y-6">
        <div>
          <label className="block text-sm text-gray-700 dark:text-gray-300 mb-2">
            GASP Table
          </label>
          <div className="grid grid-cols-3 gap-3">
            {[
              { id: 'keep', name: 'Keep Original', description: 'Preserve existing table' },
              { id: 'fix', name: 'Fix Table', description: 'Optimize for modern screens' },
              { id: 'remove', name: 'Remove Table', description: 'Remove GASP data' }
            ].map((option) => (
              <label
                key={option.id}
                className={`flex items-start p-3 rounded-lg border transition-colors cursor-pointer ${
                  value.gaspTable === option.id
                    ? 'border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-800'
                    : 'border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800/50'
                }`}
              >
                <input
                  type="radio"
                  name="gaspTable"
                  value={option.id}
                  checked={value.gaspTable === option.id}
                  onChange={(e) => onChange({ gaspTable: e.target.value as FontSettings['rendering']['gaspTable'] })}
                  className="mt-1 text-gray-900 dark:text-gray-100 focus:ring-0 border-gray-300 dark:border-gray-600"
                />
                <div className="ml-2">
                  <div className="text-sm text-gray-900 dark:text-gray-100">
                    {option.name}
                  </div>
                  <p className="text-xs text-gray-500 dark:text-gray-400 mt-0.5">
                    {option.description}
                  </p>
                </div>
              </label>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm text-gray-700 dark:text-gray-300 mb-2">
            Kerning
          </label>
          <div className="grid grid-cols-3 gap-3">
            {[
              { id: 'keep', name: 'Keep Original', description: 'Use existing kerning pairs' },
              { id: 'auto', name: 'Auto-generate', description: 'Generate optimal kerning' },
              { id: 'remove', name: 'Remove Kerning', description: 'Remove kerning data' }
            ].map((option) => (
              <label
                key={option.id}
                className={`flex items-start p-3 rounded-lg border transition-colors cursor-pointer ${
                  value.kerning === option.id
                    ? 'border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-800'
                    : 'border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800/50'
                }`}
              >
                <input
                  type="radio"
                  name="kerning"
                  value={option.id}
                  checked={value.kerning === option.id}
                  onChange={(e) => onChange({ kerning: e.target.value as FontSettings['rendering']['kerning'] })}
                  className="mt-1 text-gray-900 dark:text-gray-100 focus:ring-0 border-gray-300 dark:border-gray-600"
                />
                <div className="ml-2">
                  <div className="text-sm text-gray-900 dark:text-gray-100">
                    {option.name}
                  </div>
                  <p className="text-xs text-gray-500 dark:text-gray-400 mt-0.5">
                    {option.description}
                  </p>
                </div>
              </label>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}