import type { FontSettings } from '../types/font';

export const HINTING_OPTIONS = [
  {
    id: 'keep',
    name: 'Keep Existing',
    description: 'Preserve original instructions',
  },
  {
    id: 'ttfautohint',
    name: 'TTFAutohint',
    description: 'Automatic optimization',
  },
  {
    id: 'none',
    name: 'No Hinting',
    description: 'Remove hinting data',
  }
] as const;

export function getHintingDescription(value: FontSettings['optimization']['hinting']): string {
  return HINTING_OPTIONS.find(option => option.id === value)?.description || '';
}