import React, { useState, useCallback } from 'react';
import { EmptyState } from './EmptyState';
import { FileList } from './FileList';
import { DragOverlay } from './DragOverlay';
import { Toast } from '../common/Toast';
import type { FontFile } from '../../types/font';

interface Props {
  files: FontFile[];
  selectedFontId?: string | null;
  onFilesAdded: (files: File[]) => void;
  onFileRemove: (id: string) => void;
  onFontSelect?: (id: string) => void;
}

export function UploadArea({
  files,
  selectedFontId,
  onFilesAdded,
  onFileRemove,
  onFontSelect,
}: Props) {
  const [isDragging, setIsDragging] = useState(false);
  const [showError, setShowError] = useState(false);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const processFiles = useCallback((fileList: FileList | null) => {
    if (!fileList) return;
    
    const validFiles = Array.from(fileList).filter(isValidFontFile);
    const hasInvalidFiles = validFiles.length < fileList.length;

    if (hasInvalidFiles) {
      setShowError(true);
    }

    if (validFiles.length > 0) {
      onFilesAdded(validFiles);
    }
  }, [onFilesAdded]);

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    processFiles(e.dataTransfer.files);
  }, [processFiles]);

  const handleFileInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    processFiles(e.target.files);
    // Reset input value to allow selecting the same file again
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, [processFiles]);

  const handleBrowse = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleAddMore = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    handleBrowse();
  }, [handleBrowse]);

  const handleAreaClick = useCallback((e: React.MouseEvent) => {
    // Only trigger browse if clicking the empty area
    if (e.target === e.currentTarget || (e.target as HTMLElement).closest('.empty-state-area')) {
      handleBrowse();
    }
  }, [handleBrowse]);

  return (
    <div className="h-full min-h-[600px]">
      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        accept=".ttf,.otf,.woff,.woff2"
        multiple
        onChange={handleFileInput}
      />
      
      <div 
        className="relative h-full cursor-pointer"
        onClick={handleAreaClick}
        onDragEnter={() => setIsDragging(true)}
        onDragLeave={(e) => {
          if (e.currentTarget.contains(e.relatedTarget as Node)) return;
          setIsDragging(false);
        }}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDrop={handleDrop}
      >
        {/* Background */}
        <div className="absolute inset-0 bg-white dark:bg-dark-surface rounded-xl" />

        {/* Border and Content */}
        <div className="relative h-full border-2 border-dashed border-gray-200 dark:border-dark-border rounded-xl transition-colors duration-300">
          <div className="absolute inset-0">
            {files.length === 0 ? (
              <div className="flex items-center justify-center h-full empty-state-area">
                <EmptyState onBrowse={handleBrowse} />
              </div>
            ) : (
              <div onClick={e => e.stopPropagation()}>
                <FileList
                  files={files}
                  selectedFontId={selectedFontId}
                  isUploading={false}
                  onAddMore={handleAddMore}
                  onFileSelect={onFontSelect}
                  onFileRemove={onFileRemove}
                />
              </div>
            )}
          </div>
        </div>

        {/* Drag Overlay */}
        <DragOverlay isVisible={isDragging} />

        {/* Error Toast */}
        <Toast
          message="Invalid file type. Please upload TTF, OTF, WOFF, or WOFF2 files only."
          isVisible={showError}
          onClose={() => setShowError(false)}
        />
      </div>
    </div>
  );
}

function isValidFontFile(file: File): boolean {
  const validTypes = [
    'font/ttf',
    'font/otf',
    'font/woff',
    'font/woff2',
    'application/x-font-ttf',
    'application/x-font-otf',
    'application/font-woff',
    'application/font-woff2'
  ];

  if (validTypes.includes(file.type)) return true;

  const ext = file.name.split('.').pop()?.toLowerCase();
  return ['ttf', 'otf', 'woff', 'woff2'].includes(ext || '');
}