import React from 'react';
import { Check } from 'lucide-react';
import { useStepProgress } from '../hooks/useStepProgress';

const STEPS = [
  { number: 1, title: 'Upload' },
  { number: 2, title: 'Configure' },
  { number: 3, title: 'Preview' },
  { number: 4, title: 'Download' }
] as const;

export function StepsHeader() {
  const { 
    currentStep, 
    completedSteps,
    canAdvance,
    handleStepClick
  } = useStepProgress();

  return (
    <div className="flex items-center gap-2">
      {STEPS.map((step, index) => (
        <React.Fragment key={step.number}>
          <button
            onClick={() => canAdvance(step.number) && handleStepClick(step.number)}
            disabled={!canAdvance(step.number)}
            className={`group flex items-center gap-1.5 transition-all ${
              !canAdvance(step.number) ? 'cursor-not-allowed opacity-50' : 'hover:opacity-80'
            }`}
          >
            <div className={`
              flex items-center justify-center w-5 h-5 rounded-full transition-colors
              ${completedSteps.includes(step.number)
                ? 'bg-gray-900 dark:bg-white'
                : step.number === currentStep
                ? 'border-2 border-gray-900 dark:border-white'
                : 'border-2 border-gray-300 dark:border-gray-600'
              }
            `}>
              {completedSteps.includes(step.number) ? (
                <Check className="w-3 h-3 text-white dark:text-gray-900" />
              ) : (
                <span className={`text-xs font-normal ${
                  step.number === currentStep
                    ? 'text-gray-900 dark:text-white'
                    : 'text-gray-500 dark:text-gray-400'
                }`}>
                  {step.number}
                </span>
              )}
            </div>
            <span className={`text-xs font-normal capitalize ${
              step.number === currentStep
                ? 'text-gray-900 dark:text-white'
                : 'text-gray-500 dark:text-gray-400'
            }`}>
              {step.title}
            </span>
          </button>
          {index < STEPS.length - 1 && (
            <div className={`w-4 h-px transition-colors ${
              completedSteps.includes(step.number)
                ? 'bg-gray-900 dark:bg-white'
                : 'bg-gray-200 dark:bg-gray-700'
            }`} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}