import React from 'react';
import { Upload, Settings, Eye } from 'lucide-react';

interface Props {
  step: number;
  title: string;
}

const STEP_ICONS = {
  1: Upload,
  2: Settings,
  3: Eye,
} as const;

const STEP_TITLES = {
  1: 'Upload Fonts',
  2: 'Output Settings',
  3: 'Preview'
} as const;

export function StepHeading({ step, title }: Props) {
  const Icon = STEP_ICONS[step as keyof typeof STEP_ICONS];

  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center gap-3">
        <div className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-900 dark:bg-white">
          <span className="text-sm font-normal text-white dark:text-gray-900">
            {step}
          </span>
        </div>
        <div className="flex items-center gap-2">
          <h2 className="text-sm font-normal text-gray-900 dark:text-white">
            {STEP_TITLES[step as keyof typeof STEP_TITLES]}
          </h2>
          <span className="text-xs text-gray-500 dark:text-gray-400">
            {title}
          </span>
        </div>
      </div>
      <Icon className="w-4 h-4 text-gray-500 dark:text-gray-400" />
    </div>
  );
}