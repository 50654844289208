import React from 'react';
import { Grid, List } from 'lucide-react';
import { Tooltip } from '../common/Tooltip';

interface Props {
  mode: 'preview' | 'grid';
  onChange: (mode: 'preview' | 'grid') => void;
}

export function ViewModeToggle({ mode, onChange }: Props) {
  return (
    <div className="inline-flex gap-0.5 p-0.5 bg-gray-100 dark:bg-gray-800 rounded-lg">
      <Tooltip content="Single character view" position="bottom">
        <button
          onClick={() => onChange('preview')}
          className={`p-1.5 rounded transition-colors ${
            mode === 'preview'
              ? 'bg-white dark:bg-dark-surface text-gray-900 dark:text-gray-100 shadow-sm'
              : 'text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100'
          }`}
          aria-label="Preview mode"
        >
          <List className="w-3.5 h-3.5" />
        </button>
      </Tooltip>
      <Tooltip content="Character grid view" position="bottom">
        <button
          onClick={() => onChange('grid')}
          className={`p-1.5 rounded transition-colors ${
            mode === 'grid'
              ? 'bg-white dark:bg-dark-surface text-gray-900 dark:text-gray-100 shadow-sm'
              : 'text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100'
          }`}
          aria-label="Grid mode"
        >
          <Grid className="w-3.5 h-3.5" />
        </button>
      </Tooltip>
    </div>
  );
}