import React, { useEffect } from 'react';
import { AlertCircle, X } from 'lucide-react';

interface Props {
  message: string;
  isVisible: boolean;
  onClose: () => void;
  duration?: number;
}

export function Toast({ message, isVisible, onClose, duration = 2000 }: Props) {
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        onClose();
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [isVisible, duration, onClose]);

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-8 left-1/2 -translate-x-1/2 z-[1000]">
      <div className="flex items-center gap-3 px-6 py-4 bg-gray-900 dark:bg-white text-white dark:text-gray-900 rounded-lg shadow-lg animate-fade-in max-w-sm text-center">
        <AlertCircle className="w-5 h-5 shrink-0" />
        <p className="text-sm font-medium text-center flex-1">{message}</p>
        <button
          onClick={onClose}
          className="p-1 -m-1 ml-2 hover:opacity-80 transition-opacity shrink-0"
        >
          <X className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
}