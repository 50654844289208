import { SAMPLE_TEXTS } from '../constants/sampleTexts';

interface TypeSpecimen {
  displayText: string;
  subtitle: string;
  characterSet: string;
  sizes: Array<{ size: number; text: string }>;
  paragraphs: string[];
}

const DISPLAY_TEXTS = [
  'Typography',
  'Letterforms',
  'Characters',
  'Expression',
  'Typeface'
];

const SUBTITLES = [
  'A visual language of form and space',
  'Where art meets communication',
  'Crafting visual harmony through type',
  'The architecture of written language',
  'Design through letterforms'
];

const CHARACTER_SETS = [
  'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  'abcdefghijklmnopqrstuvwxyz',
  '0123456789',
  '!@#$%^&*()_+-=[]{}|;:,.<>?'
];

const TEXT_SIZES = [
  { size: 96, text: 'Display' },
  { size: 72, text: 'Heading' },
  { size: 48, text: 'Subheading' },
  { size: 36, text: 'Title' },
  { size: 24, text: 'Subtitle' },
  { size: 18, text: 'Body Large' },
  { size: 16, text: 'Body' },
  { size: 14, text: 'Small' },
  { size: 12, text: 'Caption' }
];

const PARAGRAPHS = [
  'Typography is the art and technique of arranging type to make written language legible, readable, and appealing when displayed.',
  'The arrangement of type involves selecting typefaces, point sizes, line lengths, line-spacing, and letter-spacing.',
  'Good typography enhances the readability and visual appeal of text while conveying the intended message effectively.',
  'Type design is the art of creating letterforms and characters that work together harmoniously.'
];

function getRandomItem<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

export function generateTypeSpecimen(): TypeSpecimen {
  return {
    displayText: getRandomItem(DISPLAY_TEXTS),
    subtitle: getRandomItem(SUBTITLES),
    characterSet: CHARACTER_SETS.join('\n'),
    sizes: TEXT_SIZES,
    paragraphs: PARAGRAPHS
  };
}