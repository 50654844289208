import React, { useEffect, useCallback, useState } from 'react';
import { ChevronDown, Type } from 'lucide-react';
import type { FontSettings, FontFile } from '../../../types/font';
import { OPENTYPE_FEATURES, FEATURE_CATEGORIES } from '../../../constants/openTypeFeatures';
import { detectStylisticSets, type OpenTypeFeature } from '../../../utils/fontFeatures';

interface Props {
  settings: FontSettings;
  font: FontFile;
  onSettingsChange: (settings: Partial<FontSettings>) => void;
}

export function OpenTypeFeatures({ settings, font, onSettingsChange }: Props) {
  const [expandedCategories, setExpandedCategories] = useState<string[]>(['standard']);
  const [stylisticSets, setStylisticSets] = useState<OpenTypeFeature[]>([]);

  // Detect available stylistic sets when font changes
  useEffect(() => {
    let mounted = true;

    const detectFeatures = async () => {
      try {
        const features = await detectStylisticSets(font);
        if (mounted) {
          setStylisticSets(features);
        }
      } catch (error) {
        console.warn('Error detecting stylistic sets:', error);
      }
    };

    detectFeatures();
    return () => { mounted = false; };
  }, [font]);

  const toggleCategory = (categoryId: string) => {
    setExpandedCategories(prev => 
      prev.includes(categoryId) 
        ? prev.filter(id => id !== categoryId)
        : [...prev, categoryId]
    );
  };

  const handleFeatureToggle = useCallback((featureId: string, enabled: boolean) => {
    const currentFeatures = settings.features || [];
    const newFeatures = enabled
      ? [...new Set([...currentFeatures, featureId])]
      : currentFeatures.filter(f => f !== featureId);
    
    onSettingsChange({ features: newFeatures });
  }, [settings.features, onSettingsChange]);

  // Combine standard features with detected stylistic sets
  const allFeatures = React.useMemo(() => {
    const standardFeatures = OPENTYPE_FEATURES.filter(f => !f.id.startsWith('ss'));
    return [...standardFeatures, ...stylisticSets];
  }, [stylisticSets]);

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center gap-2 mb-4">
        <div className="p-1.5 bg-gray-100 dark:bg-gray-800 rounded">
          <Type className="w-4 h-4 text-gray-900 dark:text-gray-100" />
        </div>
        <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">
          OpenType Features
        </h3>
      </div>

      {/* Feature Categories */}
      <div className="space-y-3">
        {FEATURE_CATEGORIES.map(category => {
          const isExpanded = expandedCategories.includes(category.id);
          let features = allFeatures.filter(f => {
            if (category.id === 'stylistic') {
              return f.id.startsWith('ss');
            }
            return category.features.includes(f.id);
          });

          // Skip category if no features available
          if (features.length === 0) return null;

          return (
            <div key={category.id} className="border border-gray-200 dark:border-dark-border rounded-lg">
              <button
                onClick={() => toggleCategory(category.id)}
                className="w-full flex items-center justify-between p-4 text-left"
              >
                <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
                  {category.name}
                  {category.id === 'stylistic' && stylisticSets.length > 0 && (
                    <span className="ml-2 text-xs text-gray-500">
                      ({stylisticSets.length} available)
                    </span>
                  )}
                </span>
                <ChevronDown 
                  className={`w-4 h-4 text-gray-500 transition-transform ${
                    isExpanded ? 'rotate-180' : ''
                  }`}
                />
              </button>
              
              {isExpanded && (
                <div className="px-4 pb-4 grid grid-cols-2 gap-3">
                  {features.map(feature => (
                    <label
                      key={feature.id}
                      className={`flex items-start p-3 rounded-lg border transition-colors cursor-pointer ${
                        settings.features?.includes(feature.id)
                          ? 'border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-800'
                          : 'border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800/50'
                      }`}
                    >
                      <input
                        type="checkbox"
                        checked={settings.features?.includes(feature.id) || false}
                        onChange={(e) => handleFeatureToggle(feature.id, e.target.checked)}
                        className="mt-1 rounded border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100 focus:ring-0"
                      />
                      <div className="ml-2">
                        <div className="text-sm text-gray-900 dark:text-gray-100">
                          {feature.name}
                        </div>
                        <p className="text-xs text-gray-500 dark:text-gray-400 mt-0.5">
                          {feature.description}
                        </p>
                      </div>
                    </label>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}