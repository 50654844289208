import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { FontSettings } from '../types/font';
import { DEFAULT_SETTINGS } from '../constants/defaultSettings';

interface SettingsState {
  settings: Record<string, FontSettings>;
  rememberSettings: boolean;
  updateSettings: (fontId: string, settings: Partial<FontSettings>) => void;
  getSettings: (fontId: string) => FontSettings;
  clearSettings: (fontId: string) => void;
  setRememberSettings: (remember: boolean) => void;
}

export const useSettingsStore = create<SettingsState>()(
  persist(
    (set, get) => ({
      settings: {},
      rememberSettings: true,

      updateSettings: (fontId, newSettings) => {
        set((state) => {
          const currentSettings = state.settings[fontId] || { ...DEFAULT_SETTINGS };
          
          // Deep merge settings
          const updatedSettings = {
            ...currentSettings,
            ...newSettings,
            optimization: {
              ...currentSettings.optimization,
              ...(newSettings.optimization || {})
            },
            rendering: {
              ...currentSettings.rendering,
              ...(newSettings.rendering || {})
            },
            opentype: {
              ...currentSettings.opentype,
              ...(newSettings.opentype || {}),
              features: newSettings.opentype?.features !== undefined 
                ? [...new Set(newSettings.opentype.features)]
                : currentSettings.opentype?.features
            },
            features: newSettings.features !== undefined 
              ? [...new Set(newSettings.features)]
              : currentSettings.features,
            formats: newSettings.formats || currentSettings.formats || DEFAULT_SETTINGS.formats
          };

          return {
            settings: {
              ...state.settings,
              [fontId]: updatedSettings
            }
          };
        });
      },

      getSettings: (fontId) => {
        const state = get();
        const settings = state.settings[fontId];
        return settings ? { ...DEFAULT_SETTINGS, ...settings } : { ...DEFAULT_SETTINGS };
      },

      clearSettings: (fontId) => {
        set((state) => {
          const { [fontId]: _, ...rest } = state.settings;
          return { settings: rest };
        });
      },

      setRememberSettings: (remember) => {
        set((state) => {
          if (!remember) {
            return { settings: {}, rememberSettings: false };
          }
          return { rememberSettings: true };
        });
      }
    }),
    {
      name: 'font-settings-storage',
      version: 1
    }
  )
);