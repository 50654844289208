import React from 'react';
import type { FontFile } from '../../types/font';
import { formatUnicode } from '../../utils/unicodeUtils';
import { getCharacterName } from '../../utils/unicodeData';
import { Tooltip } from '../common/Tooltip';

interface Props {
  font: FontFile;
  fontLoaded: boolean;
  currentChar: number;
  range: { start: number; end: number };
  onCharSelect: (charCode: number) => void;
}

export function CharacterGrid({ 
  font, 
  fontLoaded, 
  currentChar, 
  range,
  onCharSelect
}: Props) {
  const [hoveredChar, setHoveredChar] = React.useState<number | null>(null);
  
  const chars = React.useMemo(() => {
    return Array.from(
      { length: range.end - range.start + 1 }, 
      (_, i) => range.start + i
    );
  }, [range]);

  return (
    <div className="h-[400px] flex flex-col">
      {/* Grid Header */}
      <div className="flex items-center justify-between mb-2 h-8">
        <div className="text-xs font-mono text-gray-500 dark:text-gray-400">
          {formatUnicode(range.start)}-{formatUnicode(range.end)}
        </div>
        {hoveredChar && (
          <div className="text-xs font-medium text-gray-900 dark:text-gray-100 animate-fade-in">
            {getCharacterName(hoveredChar)}
          </div>
        )}
      </div>

      {/* Character Grid */}
      <div className="relative flex-1 grid grid-cols-7 sm:grid-cols-9 lg:grid-cols-11 gap-0.5 p-3 bg-white dark:bg-dark-surface rounded-lg border border-gray-200 dark:border-dark-border overflow-y-auto">
        {chars.map(charCode => (
          <Tooltip 
            key={charCode}
            content={formatUnicode(charCode)}
            position="top"
          >
            <button
              onClick={() => onCharSelect(charCode)}
              onMouseEnter={() => setHoveredChar(charCode)}
              onMouseLeave={() => setHoveredChar(null)}
              className="group relative w-full aspect-square flex items-center justify-center transition-colors"
            >
              <div className={`absolute inset-0 transition-colors ${
                charCode === currentChar
                  ? 'bg-gray-100 dark:bg-gray-800'
                  : 'group-hover:bg-gray-50 dark:group-hover:bg-gray-800/50'
              }`} />
              <span
                className={`relative text-lg leading-none select-none transition-transform ${
                  charCode === currentChar
                    ? 'text-gray-900 dark:text-gray-100 scale-110'
                    : 'text-gray-600 dark:text-gray-400 group-hover:scale-110'
                }`}
                style={{
                  fontFamily: fontLoaded && font ? `PreviewFont-${font.id}` : 'inherit',
                }}
              >
                {String.fromCharCode(charCode)}
              </span>
            </button>
          </Tooltip>
        ))}
      </div>
    </div>
  );
}