import React from 'react';
import { Ruler } from 'lucide-react';
import type { FontSettings } from '../../../types/font';

interface Props {
  value: FontSettings['metrics'];
  onChange: (value: Partial<FontSettings['metrics']>) => void;
}

export function VerticalMetricsSection({ value, onChange }: Props) {
  const handleModeChange = (mode: FontSettings['metrics']['mode']) => {
    const newMetrics = {
      mode,
      ...(mode === 'custom' ? {
        ascent: value.ascent ?? 2048,
        descent: value.descent ?? -512,
        lineGap: value.lineGap ?? 0
      } : {})
    };
    onChange(newMetrics);
  };

  const handleMetricChange = (metric: keyof FontSettings['metrics'], val: string) => {
    const numValue = parseInt(val);
    if (!isNaN(numValue)) {
      onChange({
        ...value,
        mode: 'custom',
        [metric]: numValue
      });
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-2">
        <Ruler className="w-4 h-4 text-gray-400 dark:text-gray-500" />
        <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">
          Vertical Metrics
        </h3>
      </div>

      <div className="grid grid-cols-3 gap-3">
        {[
          { id: 'auto', name: 'Auto-Adjust', description: 'Automatically optimize metrics' },
          { id: 'none', name: 'No Adjustment', description: 'Keep original metrics' },
          { id: 'custom', name: 'Custom', description: 'Set custom metric values' }
        ].map((option) => (
          <label
            key={option.id}
            className={`flex items-start p-3 rounded-lg border transition-colors cursor-pointer ${
              value.mode === option.id
                ? 'border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-800'
                : 'border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800/50'
            }`}
          >
            <input
              type="radio"
              name="metricsMode"
              value={option.id}
              checked={value.mode === option.id}
              onChange={(e) => handleModeChange(e.target.value as FontSettings['metrics']['mode'])}
              className="mt-1 text-gray-900 dark:text-gray-100 focus:ring-0 border-gray-300 dark:border-gray-600"
            />
            <div className="ml-2">
              <div className="text-sm text-gray-900 dark:text-gray-100">
                {option.name}
              </div>
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-0.5">
                {option.description}
              </p>
            </div>
          </label>
        ))}
      </div>

      {value.mode === 'custom' && (
        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm text-gray-700 dark:text-gray-300 mb-2">
              Ascent
            </label>
            <input
              type="number"
              value={value.ascent ?? 2048}
              onChange={(e) => handleMetricChange('ascent', e.target.value)}
              className="w-full text-sm bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg px-3 py-2"
            />
          </div>
          <div>
            <label className="block text-sm text-gray-700 dark:text-gray-300 mb-2">
              Descent
            </label>
            <input
              type="number"
              value={value.descent ?? -512}
              onChange={(e) => handleMetricChange('descent', e.target.value)}
              className="w-full text-sm bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg px-3 py-2"
            />
          </div>
          <div>
            <label className="block text-sm text-gray-700 dark:text-gray-300 mb-2">
              Line Gap
            </label>
            <input
              type="number"
              value={value.lineGap ?? 0}
              onChange={(e) => handleMetricChange('lineGap', e.target.value)}
              className="w-full text-sm bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg px-3 py-2"
            />
          </div>
        </div>
      )}
    </div>
  );
}