import opentype from 'opentype.js';
import { UNICODE_RANGES } from './unicodeRanges';
import type { FontMetadata } from '../types/font';

export async function getFontMetadata(fontFile: File): Promise<FontMetadata> {
  const buffer = await fontFile.arrayBuffer();
  try {
    const font = await new Promise<opentype.Font>((resolve, reject) => {
      try {
        const arrayBuffer = buffer.slice(0);
        const font = opentype.parse(arrayBuffer);
        resolve(font);
      } catch (error) {
        reject(error);
      }
    });

    const names = font.names;
    const glyphs = font.glyphs.glyphs;
    
    const unicodeSet = new Set<number>();
    Object.values(glyphs).forEach(glyph => {
      if (glyph.unicode) {
        unicodeSet.add(glyph.unicode);
      }
    });

    const supportedRanges = UNICODE_RANGES.filter(range => {
      const [start, end] = range.range.replace('U+', '').split('-').map(hex => parseInt(hex, 16));
      return Array.from(unicodeSet).some(code => code >= start && code <= end);
    }).map(range => range.range);

    return {
      postscriptName: names.postScriptName?.en || '',
      familyName: names.fontFamily?.en || '',
      subfamilyName: names.fontSubfamily?.en || '',
      fullName: names.fullName?.en || '',
      supportedRanges,
    };
  } catch (error) {
    console.error('Error parsing font:', error);
    return {
      postscriptName: '',
      familyName: fontFile.name.split('.')[0],
      subfamilyName: '',
      fullName: fontFile.name,
      supportedRanges: ['U+0020-007F'],
    };
  }
}

export function sanitizeFontName(name: string): string {
  // Remove file extension if present
  const baseName = name.split('.')[0];
  
  // Split on common delimiters and clean each part
  const parts = baseName
    .split(/[-_\s]+/)
    .map(part => part.toLowerCase().trim())
    .filter(Boolean);

  // Join parts with hyphens
  return parts.join('-');
}

export function detectFontType(file: File): string {
  // First try to detect from file extension
  const ext = file.name.split('.').pop()?.toLowerCase();
  if (ext) {
    switch (ext) {
      case 'ttf': return 'font/ttf';
      case 'otf': return 'font/otf';
      case 'woff': return 'font/woff';
      case 'woff2': return 'font/woff2';
    }
  }

  // Then try the file's MIME type
  if (file.type && file.type.startsWith('font/')) {
    return file.type;
  }

  // Finally check for application MIME types
  switch (file.type) {
    case 'application/x-font-ttf':
    case 'application/x-font-truetype':
      return 'font/ttf';
    case 'application/x-font-otf':
    case 'application/x-font-opentype':
      return 'font/otf';
    case 'application/font-woff':
      return 'font/woff';
    case 'application/font-woff2':
      return 'font/woff2';
    default:
      // Default to TTF if we can't determine the type
      return 'font/ttf';
  }
}

export function createFontPreviewUrl(arrayBuffer: ArrayBuffer, type: string): string {
  const blob = new Blob([arrayBuffer], { type });
  return URL.createObjectURL(blob);
}