import JSZip from 'jszip';

export async function downloadWithSaveDialog(
  content: Blob,
  suggestedName: string,
  onSuccess?: () => void
): Promise<void> {
  try {
    // Try to use the File System Access API
    if ('showSaveFilePicker' in window) {
      const handle = await window.showSaveFilePicker({
        suggestedName,
        types: [{
          description: 'ZIP Archive',
          accept: { 'application/zip': ['.zip'] }
        }]
      });
      const writable = await handle.createWritable();
      await writable.write(content);
      await writable.close();
      onSuccess?.();
      return;
    }
  } catch (error) {
    console.warn('File System Access API not available, falling back to traditional download');
  }

  // Fallback to traditional download
  const url = URL.createObjectURL(content);
  const link = document.createElement('a');
  link.href = url;
  link.download = suggestedName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
  onSuccess?.();
}

export async function downloadZipFile(
  zip: JSZip,
  suggestedName: string,
  onSuccess?: () => void
): Promise<void> {
  const content = await zip.generateAsync({ type: 'blob' });
  await downloadWithSaveDialog(content, suggestedName, onSuccess);
}