import React from 'react';
import { Image } from 'lucide-react';
import type { TextAlignment } from '../types';

interface Props {
  headingFont: string;
  bodyFont: string;
  primarySize: number;
  secondarySize: number;
  textAlignment: TextAlignment;
  getLineHeight: (size: number) => number;
  content: any;
  theme: 'light' | 'dark';
}

export function ContentSection({
  headingFont,
  bodyFont,
  primarySize,
  secondarySize,
  textAlignment,
  getLineHeight,
  content,
  theme
}: Props) {
  return (
    <div className={`py-16 transition-colors ${
      theme === 'dark' ? 'bg-gray-900' : 'bg-white'
    }`}>
      <div className="max-w-4xl mx-auto px-8">
        <div className="grid grid-cols-2 gap-16 items-center">
          <div className="space-y-6">
            <h2 
              className={theme === 'dark' ? 'text-white' : 'text-gray-900'}
              style={{ 
                fontFamily: headingFont,
                fontSize: `${Math.round(primarySize * 0.7)}px`,
                lineHeight: getLineHeight(Math.round(primarySize * 0.7)),
                textAlign: textAlignment
              }}
            >
              {content.content.title}
            </h2>
            <p 
              className={theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}
              style={{ 
                fontFamily: bodyFont,
                fontSize: `${secondarySize}px`,
                lineHeight: getLineHeight(secondarySize),
                textAlign: textAlignment
              }}
            >
              {content.content.description}
            </p>
          </div>
          <div className={`aspect-square rounded-lg border flex items-center justify-center ${
            theme === 'dark' 
              ? 'bg-gray-800 border-gray-700' 
              : 'bg-gray-50 border-gray-100'
          }`}>
            <div className={theme === 'dark' ? 'text-gray-700' : 'text-gray-200'}>
              <Image className="w-16 h-16" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}