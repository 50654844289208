import React from 'react';
import { Sun, Moon } from 'lucide-react';

interface Props {
  theme: 'light' | 'dark';
  onChange: (theme: 'light' | 'dark') => void;
  hideLabel?: boolean;
}

export function ThemeToggle({ theme, onChange, hideLabel = false }: Props) {
  return (
    <div className="flex gap-0.5 p-0.5 bg-gray-100 dark:bg-gray-800 rounded-lg">
      <button
        onClick={() => onChange('light')}
        className={`flex items-center gap-1.5 px-3 py-1.5 rounded transition-colors ${
          theme === 'light'
            ? 'bg-white text-gray-900 shadow-sm'
            : 'text-gray-500 hover:text-gray-900 dark:hover:text-gray-100'
        }`}
        title="Light mode"
      >
        <Sun className="w-4 h-4" />
        {!hideLabel && <span className="text-sm font-medium">Light</span>}
      </button>
      <button
        onClick={() => onChange('dark')}
        className={`flex items-center gap-1.5 px-3 py-1.5 rounded transition-colors ${
          theme === 'dark'
            ? 'bg-gray-700 text-white shadow-sm'
            : 'text-gray-500 hover:text-gray-900 dark:hover:text-gray-100'
        }`}
        title="Dark mode"
      >
        <Moon className="w-4 h-4" />
        {!hideLabel && <span className="text-sm font-medium">Dark</span>}
      </button>
    </div>
  );
}