import React, { useState } from 'react';
import { Settings2, X } from 'lucide-react';
import type { FontFile } from '../../types/font';
import { FormatSelector } from './FormatSelector';
import { TechnicalSettings } from './TechnicalSettings';
import { CSSPreview } from './CSSPreview';
import { OutputSizeInfo } from '../preview/OutputSizeInfo';
import { FileFormatIcon } from '../icons/FileFormatIcon';
import { getFriendlyFileType } from '../../utils/fileTypeUtils';
import { useStepProgress } from '../../hooks/useStepProgress';
import { ConfirmDialog } from '../modals/ConfirmDialog';
import { useSettingsStore } from '../../store/settingsStore';

interface Props {
  font: FontFile;
  onAdvancedClick?: () => void;
  onRemove?: (id: string) => void;
}

export function FontSettingsPanel({ font, onAdvancedClick, onRemove }: Props) {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const format = getFriendlyFileType(font);
  const { completeStep } = useStepProgress();
  const { getSettings } = useSettingsStore();
  const settings = getSettings(font.id);

  const handlePreviewClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    completeStep(3);
    onAdvancedClick?.();
  };

  const handleRemoveClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowConfirmDialog(true);
  };

  const handleConfirmRemove = () => {
    onRemove?.(font.id);
    setShowConfirmDialog(false);
  };

  return (
    <>
      <div className="bg-white dark:bg-dark-surface rounded-lg border border-gray-200 dark:border-dark-border overflow-hidden">
        <div className="p-4 flex items-center justify-between">
          <div className="flex items-center gap-3">
            <FileFormatIcon format={format} size="sm" />
            <span className="text-sm font-medium text-gray-900 dark:text-white">
              {font.name}
            </span>
            <OutputSizeInfo font={font} settings={settings} />
          </div>

          <div className="flex items-center gap-2">
            <button
              onClick={handlePreviewClick}
              className="flex items-center gap-1.5 px-3 py-1.5 text-sm font-medium text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-lg transition-colors"
            >
              <Settings2 className="w-3.5 h-3.5" />
              <span className="hidden sm:inline">Advanced Settings</span>
            </button>
            {onRemove && (
              <button
                onClick={handleRemoveClick}
                className="p-1.5 text-gray-400 hover:text-gray-900 dark:text-gray-500 dark:hover:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
              >
                <X className="w-4 h-4" />
              </button>
            )}
          </div>
        </div>

        <div className="p-4 space-y-6 border-t border-gray-200 dark:border-dark-border">
          <FormatSelector fontId={font.id} />
          <TechnicalSettings font={font} />
          <CSSPreview font={font} settings={settings} />
        </div>
      </div>

      <ConfirmDialog
        isOpen={showConfirmDialog}
        title="Remove Font"
        message={`Are you sure you want to remove "${font.name}"? This action cannot be undone.`}
        onConfirm={handleConfirmRemove}
        onCancel={() => setShowConfirmDialog(false)}
      />
    </>
  );
}