import React from 'react';
import { generateTypeSpecimen } from '../../../../utils/typeSpecimen';

interface Props {
  font: string;
}

export function Layout1({ font }: Props) {
  const specimen = React.useMemo(() => generateTypeSpecimen(), []);

  return (
    <div className="w-full max-w-[1200px] mx-auto min-h-screen flex flex-col justify-center space-y-48 px-8 py-24">
      {/* Hero Section */}
      <div className="text-center">
        <h1 
          className="text-[200px] leading-[0.8] text-gray-900 dark:text-gray-100 mb-12"
          style={{ fontFamily: font }}
        >
          Aa
        </h1>
        <p 
          className="text-6xl text-gray-600 dark:text-gray-400"
          style={{ fontFamily: font }}
        >
          {specimen.displayText}
        </p>
      </div>

      {/* Character Grid */}
      <div>
        <div 
          className="text-7xl leading-[1.5] text-gray-900 dark:text-gray-100 text-center tracking-wide"
          style={{ fontFamily: font }}
        >
          ABCDEFGHIJKLM
          NOPQRSTUVWXYZ
        </div>
        <div 
          className="text-7xl leading-[1.5] text-gray-600 dark:text-gray-400 text-center tracking-wide mt-12"
          style={{ fontFamily: font }}
        >
          abcdefghijklm
          nopqrstuvwxyz
        </div>
      </div>

      {/* Numbers & Symbols */}
      <div className="text-center">
        <div 
          className="text-7xl leading-[1.2] text-gray-900 dark:text-gray-100 mb-12"
          style={{ fontFamily: font }}
        >
          0123456789
        </div>
        <div 
          className="text-6xl leading-[1.2] text-gray-600 dark:text-gray-400"
          style={{ fontFamily: font }}
        >
          !@#$%^&*()
        </div>
      </div>

      {/* Text Sizes */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-16">
        {specimen.sizes.map(({ size, text }) => (
          <div key={size} className="text-center">
            <p 
              className="text-gray-900 dark:text-gray-100 mb-3 truncate"
              style={{ 
                fontFamily: font, 
                fontSize: `${size}px`,
                lineHeight: '1.2'
              }}
            >
              {text}
            </p>
            <span className="text-xs font-mono text-gray-500">
              {size}px
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}