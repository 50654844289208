import React from 'react';

interface Props {
  value: number;
  onChange: (size: number) => void;
  maxSize?: number;
  showLabel?: boolean;
}

const DEFAULT_FONT_SIZES = [12, 14, 16, 20, 24, 32, 48, 64, 72, 96, 120, 144, 192];

export function FontSizeSlider({ value, onChange, maxSize, showLabel = false }: Props) {
  const sizes = maxSize 
    ? DEFAULT_FONT_SIZES.filter(size => size <= maxSize)
    : DEFAULT_FONT_SIZES;

  // Find the closest size index
  const currentIndex = sizes.reduce((closest, size, index) => {
    const currentDiff = Math.abs(size - value);
    const closestDiff = Math.abs(sizes[closest] - value);
    return currentDiff < closestDiff ? index : closest;
  }, 0);

  return (
    <div className="flex items-center gap-2">
      <div className="relative group">
        <input
          type="range"
          min="0"
          max={sizes.length - 1}
          value={currentIndex}
          onChange={(e) => onChange(sizes[parseInt(e.target.value)])}
          className="w-32 h-1.5 bg-gray-200 dark:bg-gray-700 rounded-lg appearance-none cursor-pointer"
        />
        <div className="absolute -top-6 left-1/2 -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity">
          <div className="px-2 py-1 text-xs font-medium bg-gray-900 dark:bg-white text-white dark:text-gray-900 rounded shadow-sm whitespace-nowrap">
            {value}px
          </div>
        </div>
      </div>
      {showLabel && (
        <span className="text-xs font-mono text-gray-500 dark:text-gray-400 min-w-[3ch] text-right">
          {value}
        </span>
      )}
    </div>
  );
}