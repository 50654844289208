import React from 'react';
import { Download } from 'lucide-react';
import { useSettingsStore } from '../../store/settingsStore';
import { useStepProgress } from '../../hooks/useStepProgress';

interface Props {
  onGenerate: (type: 'individual' | 'all') => void;
  disabled: boolean;
  processing: boolean;
  multipleFiles: boolean;
}

export function DownloadSection({ onGenerate, disabled, processing, multipleFiles }: Props) {
  const { rememberSettings, setRememberSettings } = useSettingsStore();
  const { completeStep } = useStepProgress();
  const [agreedToEula, setAgreedToEula] = React.useState(false);

  const handleGenerate = (type: 'individual' | 'all') => {
    completeStep(4);
    onGenerate(type);
  };

  const isGenerateDisabled = disabled || !agreedToEula;

  return (
    <div className="bg-white dark:bg-dark-surface rounded-lg border border-gray-200 dark:border-dark-border">
      <div className="p-6 flex flex-col items-center text-center border-b border-gray-200 dark:border-dark-border">
        <div className="max-w-lg">
          <label className="flex items-start gap-3 cursor-pointer">
            <input
              type="checkbox"
              checked={agreedToEula}
              onChange={(e) => setAgreedToEula(e.target.checked)}
              className="mt-1 rounded border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100 focus:ring-0"
            />
            <div className="text-left">
              <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
                Yes, the uploaded fonts are approved for web embedding.
              </span>
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                By using font.zip, you agree to comply with the terms outlined in the{' '}
                <a 
                  href="/eula" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-gray-900 dark:text-gray-100 hover:underline"
                  onClick={(e) => e.stopPropagation()}
                >
                  EULA
                </a>.
              </p>
            </div>
          </label>
        </div>
      </div>

      <div className="p-4 flex items-center justify-between">
        <label className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-300 cursor-pointer">
          <input
            type="checkbox"
            checked={rememberSettings}
            onChange={(e) => setRememberSettings(e.target.checked)}
            className="rounded border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100 focus:ring-0"
          />
          Remember my settings
        </label>

        <button
          onClick={() => handleGenerate('all')}
          disabled={isGenerateDisabled}
          className={`relative px-4 py-2 rounded-lg font-medium flex items-center justify-center gap-2 transition-all ${
            isGenerateDisabled
              ? 'bg-gray-100 dark:bg-gray-800 cursor-not-allowed'
              : 'bg-gray-900 hover:bg-black dark:bg-white dark:hover:bg-gray-100'
          }`}
        >
          {processing ? (
            <>
              <div className="w-4 h-4 border-2 border-gray-400 border-t-transparent rounded-full animate-spin" />
              <span className={isGenerateDisabled ? 'text-gray-400 dark:text-gray-500' : 'text-white dark:text-gray-900'}>
                Processing...
              </span>
            </>
          ) : (
            <>
              <Download className={`w-4 h-4 ${
                isGenerateDisabled ? 'text-gray-400 dark:text-gray-500' : 'text-white dark:text-gray-900'
              }`} />
              <span className={isGenerateDisabled ? 'text-gray-400 dark:text-gray-500' : 'text-white dark:text-gray-900'}>
                {multipleFiles ? 'Download All' : 'Download'}
              </span>
            </>
          )}
        </button>
      </div>
    </div>
  );
}