import type { FontFile } from '../types/font';

export interface OpenTypeFeature {
  id: string;
  name: string;
  tech: string;
  description: string;
  available?: boolean;
}

// Function to detect available stylistic sets
export async function detectStylisticSets(font: FontFile): Promise<OpenTypeFeature[]> {
  const testElement = document.createElement('div');
  testElement.style.visibility = 'hidden';
  testElement.style.position = 'absolute';
  testElement.style.fontFamily = `'PreviewFont-${font.id}'`;
  testElement.textContent = 'Test';
  document.body.appendChild(testElement);

  try {
    await document.fonts.ready;
    const features: OpenTypeFeature[] = [];

    // Test stylistic sets from ss01 to ss20
    for (let i = 1; i <= 20; i++) {
      const featureId = `ss${i.toString().padStart(2, '0')}`;
      
      try {
        testElement.style.fontFeatureSettings = `"${featureId}" 1`;
        const withFeature = testElement.offsetWidth;

        testElement.style.fontFeatureSettings = `"${featureId}" 0`;
        const withoutFeature = testElement.offsetWidth;

        if (withFeature !== withoutFeature) {
          features.push({
            id: featureId,
            name: `Stylistic Set ${i}`,
            tech: featureId,
            description: `Alternative character designs (Set ${i})`,
            available: true
          });
        }
      } catch {
        continue;
      }
    }

    return features;
  } finally {
    document.body.removeChild(testElement);
  }
}