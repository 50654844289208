import React from 'react';
import { FileType, FileText } from 'lucide-react';

interface Props {
  format: string;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
}

const FORMAT_ICONS = {
  'TTF': FileType,
  'OTF': FileType,
  'WOFF': FileText,
  'WOFF2': FileText
} as const;

export function FileFormatIcon({ format, size = 'md', className = '' }: Props) {
  const sizeClasses = {
    sm: 'w-6 h-6 text-[10px]',
    md: 'w-8 h-8 text-xs',
    lg: 'w-10 h-10 text-sm'
  };

  const Icon = FORMAT_ICONS[format as keyof typeof FORMAT_ICONS] || FileType;

  return (
    <div className={`relative ${sizeClasses[size]} ${className}`}>
      <div className="absolute inset-0 rounded-lg flex items-center justify-center font-mono font-medium transition-colors text-gray-500 dark:text-gray-400">
        <Icon className="absolute w-full h-full opacity-10" />
        <span className="relative">{format}</span>
      </div>
    </div>
  );
}