import React from 'react';
import { FileText, Code } from 'lucide-react';
import type { FontFile } from '../../types/font';
import { sanitizeFontName } from '../../utils/fontUtils';
import { useSettingsStore } from '../../store/settingsStore';

interface Props {
  font: FontFile;
}

export function TechnicalSettings({ font }: Props) {
  const { updateSettings, getSettings } = useSettingsStore();
  const settings = getSettings(font.id);

  const handleCssFilenameChange = (value: string) => {
    // Ensure filename follows convention
    const sanitized = sanitizeFontName(value);
    updateSettings(font.id, { 
      cssFilename: sanitized
    });
  };

  const handleFontFamilyChange = (value: string) => {
    updateSettings(font.id, { 
      fontFamily: value 
    });
  };

  const handleBase64Change = (checked: boolean) => {
    updateSettings(font.id, { 
      embedBase64: checked 
    });
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 gap-4">
        {/* CSS Filename */}
        <div>
          <div className="relative">
            <FileText className="w-4 h-4 absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 dark:text-gray-500" />
            <input
              type="text"
              value={settings.cssFilename || sanitizeFontName(font.name)}
              onChange={(e) => handleCssFilenameChange(e.target.value)}
              className="w-full pl-10 pr-12 py-2 text-sm rounded-lg border border-gray-200 dark:border-dark-border bg-transparent dark:bg-dark-surface text-gray-900 dark:text-gray-100 focus:border-gray-900 dark:focus:border-white focus:ring-0 hover:border-gray-300 dark:hover:border-gray-600 transition-colors"
              placeholder="Enter filename"
            />
            <span className="absolute right-3 top-1/2 -translate-y-1/2 text-sm text-gray-400 dark:text-gray-500">
              .css
            </span>
          </div>
          <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
            The name of the generated CSS file
          </p>
        </div>

        {/* Font Family Name */}
        <div>
          <div className="relative">
            <Code className="w-4 h-4 absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 dark:text-gray-500" />
            <input
              type="text"
              value={settings.fontFamily || font.name}
              onChange={(e) => handleFontFamilyChange(e.target.value)}
              className="w-full pl-10 py-2 text-sm rounded-lg border border-gray-200 dark:border-dark-border bg-transparent dark:bg-dark-surface text-gray-900 dark:text-gray-100 focus:border-gray-900 dark:focus:border-white focus:ring-0 hover:border-gray-300 dark:hover:border-gray-600 transition-colors"
              placeholder="Font family name"
            />
          </div>
          <div className="mt-1 flex items-center justify-between">
            <p className="text-xs text-gray-500 dark:text-gray-400">
              The font-family name in CSS
            </p>
            <label className="flex items-center gap-2 cursor-pointer">
              <input
                type="checkbox"
                checked={settings.embedBase64}
                onChange={(e) => handleBase64Change(e.target.checked)}
                className="rounded border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100 focus:ring-0"
              />
              <span className="text-xs text-gray-600 dark:text-gray-300">Base64 Encode</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}