import React from 'react';
import { GridBackground } from '../common/GridBackground';

interface Props {
  font: string;
  text: string;
  fontSize: number;
  alignment: 'left' | 'center' | 'right';
  onChange: (text: string) => void;
  hideGrid?: boolean;
  className?: string;
}

export function Editor({ 
  font, 
  text, 
  fontSize, 
  alignment, 
  onChange,
  hideGrid = false,
  className = '' 
}: Props) {
  return (
    <div className={`relative h-full bg-white dark:bg-dark-surface ${className}`}>
      {!hideGrid && (
        <div className="absolute inset-0">
          <GridBackground className="text-gray-900 dark:text-gray-100" scroll />
        </div>
      )}
      
      <div className="relative h-full flex items-center justify-center px-4">
        <div className="w-full max-w-[1200px]">
          <textarea
            value={text}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Start typing to preview your font..."
            className="w-full min-h-[calc(100vh-16rem)] bg-transparent resize-none focus:outline-none text-gray-900 dark:text-gray-100 overflow-auto py-4"
            style={{
              fontSize: `${fontSize}px`,
              fontFamily: font,
              textAlign: alignment
            }}
          />
        </div>
      </div>
    </div>
  );
}