import React from 'react';
import { Circle, RefreshCw, X } from 'lucide-react';

interface Props {
  children: React.ReactNode;
  onClose: () => void;
  onRefresh: () => void;
}

export function BrowserFrame({ children, onClose, onRefresh }: Props) {
  return (
    <div className="w-full h-full bg-white rounded-xl shadow-2xl overflow-hidden">
      {/* Browser Chrome */}
      <div className="h-12 bg-gray-100 border-b border-gray-200 flex items-center px-4 relative z-10">
        {/* Window Controls */}
        <div className="flex items-center gap-2">
          <button 
            onClick={onRefresh}
            className="p-1.5 text-gray-500 hover:text-gray-900 hover:bg-gray-200 rounded-full transition-colors"
          >
            <RefreshCw className="w-4 h-4" />
          </button>
          <div className="flex gap-1.5">
            <Circle className="w-3 h-3 text-gray-300" />
            <Circle className="w-3 h-3 text-gray-300" />
          </div>
        </div>

        {/* URL Bar */}
        <div className="flex-1 mx-4">
          <div className="max-w-lg mx-auto h-8 bg-white border border-gray-200 rounded-lg flex items-center px-3">
            <span className="text-xs font-mono text-gray-600 truncate">
              font.zip/webfont-generator
            </span>
          </div>
        </div>

        {/* Close Button */}
        <button 
          onClick={onClose}
          className="p-1.5 text-gray-500 hover:text-gray-900 hover:bg-gray-200 rounded-full transition-colors"
        >
          <X className="w-4 h-4" />
        </button>
      </div>

      {/* Browser Content */}
      <div className="absolute inset-0 pt-12">
        <div className="h-full overflow-y-auto bg-white">
          {children}
        </div>
      </div>
    </div>
  );
}