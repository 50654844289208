export const FONT_MIME_TYPES: Record<string, string> = {
  'font/ttf': 'font/ttf',
  'font/otf': 'font/opentype',
  'font/woff': 'font/woff',
  'font/woff2': 'font/woff2',
  'application/x-font-ttf': 'font/ttf',
  'application/x-font-otf': 'font/opentype',
  'application/font-woff': 'font/woff',
  'application/font-woff2': 'font/woff2'
};

export const FONT_FORMATS: Record<string, string> = {
  'font/ttf': 'truetype',
  'font/otf': 'opentype',
  'font/woff': 'woff',
  'font/woff2': 'woff2',
  'application/x-font-ttf': 'truetype',
  'application/x-font-otf': 'opentype',
  'application/font-woff': 'woff',
  'application/font-woff2': 'woff2'
};

export function getMimeType(type: string): string {
  return FONT_MIME_TYPES[type] || 'font/ttf';
}

export function getFontFormat(type: string): string {
  return FONT_FORMATS[type] || 'truetype';
}