import React, { memo, useState, useCallback, useEffect, useRef } from 'react';
import { FileUpload } from './FileUpload';
import { QuickSettings } from './QuickSettings';
import { FontPreview } from './FontPreview';
import { DownloadSection } from './download/DownloadSection';
import { DownloadNotification } from './common/DownloadNotification';
import { useFontProcessor } from '../hooks/useFontProcessor';
import { useStepProgress } from '../hooks/useStepProgress';

export const FontGenerator = memo(function FontGenerator() {
  const { 
    files, 
    processing, 
    addFonts, 
    removeFontById, 
    updateFontSettings,
    processFont,
    showDownloadNotification,
    setShowDownloadNotification
  } = useFontProcessor();
  
  const [selectedFontId, setSelectedFontId] = useState<string | null>(null);
  const { currentStep, completedSteps, handleStepClick } = useStepProgress();
  const initializedRef = useRef(false);

  useEffect(() => {
    if (files.length > 0 && !selectedFontId && !initializedRef.current) {
      initializedRef.current = true;
      setSelectedFontId(files[files.length - 1].id);
    }
  }, [files, selectedFontId]);

  const steps = [
    { number: 1, title: 'Upload', completed: files.length > 0 },
    { number: 2, title: 'Configure', completed: completedSteps.includes(2) },
    { number: 3, title: 'Preview', completed: completedSteps.includes(3) },
    { number: 4, title: 'Download', completed: completedSteps.includes(4) }
  ];

  const handleSettingsChange = useCallback((id: string, settings: any) => {
    updateFontSettings(id, settings);
  }, [updateFontSettings]);

  const handleGenerate = useCallback(async (type: 'individual' | 'all') => {
    await processFont(type);
  }, [processFont]);

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-dark-bg transition-colors">
      <div className="max-w-[800px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-12 sm:py-20 lg:py-28 space-y-12 sm:space-y-16 lg:space-y-24">
          <div data-step="1" className="relative scroll-mt-32 mt-3">
            <FileUpload
              files={files}
              onFilesAdded={addFonts}
              onFileRemove={removeFontById}
              onFontSelect={setSelectedFontId}
              selectedFontId={selectedFontId}
            />
          </div>

          {files.length > 0 && (
            <>
              <div data-step="2" className="relative scroll-mt-32">
                <QuickSettings 
                  fonts={files}
                  onSettingsChange={handleSettingsChange}
                  onFontRemove={removeFontById}
                />
              </div>

              <div data-step="3" className="scroll-mt-32">
                <FontPreview 
                  fonts={files} 
                  onFontRemove={removeFontById}
                />
              </div>

              <div data-step="4" className="scroll-mt-32">
                <DownloadSection
                  onGenerate={handleGenerate}
                  disabled={processing}
                  processing={processing}
                  multipleFiles={files.length > 1}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <DownloadNotification 
        isVisible={showDownloadNotification}
        onClose={() => setShowDownloadNotification(false)}
      />
    </div>
  );
});